import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import { UPDATE_ADVERTISE_STATUS } from "src/graphql/mutations";
import { GET_ADVERTISE } from "src/graphql/query";
import { DELETE_ADVERTISE } from "src/graphql/mutations";
import DeleteModel from "src/components/Common/DeleteModel";
import { ClearIcon } from "@mui/x-date-pickers";
import ApprovalIcon from "@mui/icons-material/Approval";
import { toast } from "react-toastify";
import { hasPermissionPage } from "src/components/Common/Utils";
import VerifyEventsDialog from "../Events/VerifyEvents";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";

enum AdsStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

interface Advertise {
  _id: string;
  email: string;
  phoneNumber: string;
  address: string;
  companyName: string;
  statusHistory: {
    status: AdsStatus;
    adminComment: string;
    updatedAt: string;
  }[];
}

const Advertise: React.FC = () => {
  const [list, setList] = useState<Advertise[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [deleteAdvertise] = useMutation(DELETE_ADVERTISE);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteAdvertiseId, setDeleteAdvertiseId] = useState<string | null>(
    null
  );
  const [verifyingByUsAdvertise, setVerifyingByUsAdvertise] = useState<
    string | null
  >(null);
  const [reason, setReason] = useState<string>("");
  const [verifyAdvertiseByUsProfileValue, setVerifyAdvertiseByUsProfileValue] =
    useState<string>("");

  const [updateAdsStatus] = useMutation(UPDATE_ADVERTISE_STATUS);

  const handleVerifyByUs = async (eventId: string, status: string, adminComment: string): Promise<void> => {
    try {
      const newStatus = AdsStatus[status as keyof typeof AdsStatus];
      if (!newStatus) {
        toast.error("Invalid status provided");
      }
      const variables = {
        _id: eventId,
        input: { status: newStatus, adminComment },
      }
      const res = await updateAdsStatus({
        variables
      });
      if (res?.data?.updateAdsStatus) {
        const { statusCode, message } = res.data.updateAdsStatus;
        if (statusCode === 200) {
          toast.success("Status updated successfully!");
          refetch();
          setVerifyAdvertiseByUsProfileValue("");
          setReason("");
          setVerifyingByUsAdvertise(null);
        } else {
          toast.error(message || "Unknown error occurred.");
        }
      } else {
        toast.error("No data returned from mutation.");
      }
    } catch (error: any) {
      toast.error("Failed to update status. Please try again.");
      console.error("Error updating status:", error.message || error);
    }
  }

  const COLUMNS = [
    { headerName: "Email", field: "email", flex: 1 },
    { headerName: "Phone Number", field: "phoneNumber", flex: 1 },
    { headerName: "Address", field: "address", flex: 1 },
    { headerName: "Company Name", field: "companyName", flex: 1 },
    {
      headerName: "Reason",
      field: "reason",
      flex: 1,
      renderCell: ({ row }: { row: Advertise }) => (
        <span>{row?.statusHistory[row.statusHistory.length - 1]?.adminComment}</span>
      )
    },
    {
      headerName: "Verified",
      field: "statusHistory[advertise.statusHistory.length - 1]?.status",
      flex: 1,
      renderCell: ({ row }: { row: Advertise }) => (
        <>
          {row?.statusHistory[row.statusHistory.length - 1]?.status === "APPROVED" ? (
            <Tooltip title="Approved">
              <IconButton aria-label="status" style={{ color: "#039487" }}>
                <TaskAltIcon />
              </IconButton>
            </Tooltip>
          ) : row?.statusHistory[row.statusHistory.length - 1]?.status === "PENDING" ? (
            <Tooltip title="Pending">
              <IconButton aria-label="status" style={{ color: "#ff9966" }}>
                <WarningIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Rejected">
              <IconButton aria-label="status" style={{ color: "#ff0000" }}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      headerName: "Verification",
      field: "Verification",
      width: 150,
      renderCell: ({ row }: any) => (
        <>
          <Tooltip title="Verify Advertise">
            <IconButton
              onClick={() => setVerifyingByUsAdvertise(row._id)}
              aria-label="status"
              style={{ color: "#869986" }}
            >
              <ApprovalIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <Box>
          {hasPermissionPage("AdsWithUs", "delete") && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setDeleteAdvertiseId(row._id);
                  setOpenDeleteModel(true);
                }}
                aria-label="delete"
                style={{ color: "#00C5B9" }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_ADVERTISE, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data?.getAdsWithUs?.data) {
      setList(data.getAdsWithUs.data);
      setTotal(data?.getAdsWithUs?.count);
    }
  }, [data]);

  const handleDelete = async () => {
    if (!deleteAdvertiseId) return;
    try {
      const res = await deleteAdvertise({
        variables: { id: deleteAdvertiseId },
      });
      if (res?.data?.deleteAdsWithUs?.statusCode === 200) {
        toast.success(res?.data?.deleteAdsWithUs?.message);
      } else {
        throw new Error(res?.data?.deleteAdsWithUs?.message);
      }
      refetch(); // Re-fetch data after deletion
      setOpenDeleteModel(false); // Close the confirmation dialog
    } catch (error: any) {
      toast.error(error?.message);
      console.error("Error deleting advertise:", error);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: newPageSize,
    });
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteAdvertiseId(null);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        paddingRight={4}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Advertise
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page - 1, // Adjust for 0-based page index
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this advertise?"
        />
      )}
      <VerifyEventsDialog
        label="Advertise By us"
        handleVerify={handleVerifyByUs}
        setVerifyingData={setVerifyingByUsAdvertise}
        verifyingData={verifyingByUsAdvertise}
        verifyDataValue={verifyAdvertiseByUsProfileValue}
        reason={reason}
        setVerifyDataValue={setVerifyAdvertiseByUsProfileValue}
        setReason={setReason}
      />
    </Box>
  );
};

export default Advertise;

import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Checkbox,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import {
  DELETE_BLOG,
  UPDATE_BLOG_RANK,
  VERIFY_BLOG,
} from "src/graphql/mutations";
import { GET_BLOGS } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModel from "src/components/Common/DeleteModel";
import ToggleSwitch from "src/components/Common/ToggleSwitch";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import CustomButton from "src/components/CustomButton";
import VerifyEventsDialog from "../Events/VerifyEvents";
import { ClearIcon } from "@mui/x-date-pickers";
import {
  DELETE_ALL_BLOG,
  DELETE_MULTIPLE_BLOG,
} from "src/graphql/DeleteMutation";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import ActionMenu from "../Business/ActionMenu";
import { useNavigate } from "react-router";
import { ROUTES } from "src/constant";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import ApprovalIcon from "@mui/icons-material/Approval";

interface Blog {
  _id?: string;
  publishedDate?: string;
  publishedBy?: string;
  description?: string;
  createdBy?: string;
}

const Blog: React.FC = () => {
  const [list, setList] = useState<Blog[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [deleteBlog] = useMutation(DELETE_BLOG);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteBlogId, setDeleteBlogId] = useState<string | null>(null);
  const [rankUpdates, setRankUpdates] = useState<{
    [key: string]: number | null;
  }>({});
  const [isRankEdit, setIsRankEdit] = useState<boolean>(false);
  const [UpdateBlogsRanksBulk] = useMutation(UPDATE_BLOG_RANK);
  const [VerifyBlog] = useMutation(VERIFY_BLOG);
  const [reason, setReason] = useState<string>("");
  const [verifyingBlog, setVerifyingBlog] = useState<string | null>(null);
  const [verifyBlogValue, setVerifyBlogValue] = useState<string>("");
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [DeleteMultipleBlog] = useMutation(DELETE_MULTIPLE_BLOG);
  const [DeleteAllBlogs] = useMutation(DELETE_ALL_BLOG);
  const [selectedRows, setSelectedRows] = useState([]);
  const isSuperAdmin = getRole() === "SuperAdmin";
  const navigate = useNavigate();
  const COLUMNS = [
    ...(isSuperAdmin
      ? [
        {
          headerName: (
            <Box display={"flex"} alignItems={"center"}>
              <Checkbox
                checked={
                  selectedRows.length === list.length && list.length > 0
                }
                indeterminate={
                  selectedRows.length > 0 && selectedRows.length < list.length
                }
                onChange={(event) => handleSelectAll(event.target.checked)}
              />
            </Box>
          ),
          field: "select",
          flex: 0.5,
          sortable: false,
          filterable: false,
          disableClickEventBubbling: true,
          renderCell: ({ row }: any) => (
            <Checkbox
              checked={selectedRows.includes(row?._id as never)}
              onChange={() => handleRowSelect(row._id)}
            />
          ),
        },
      ]
      : []),
    // { headerName: "Published Date", field: "publishedDate", flex: 1 },
    {
      headerName: "Published Date",
      field: "publishedDate",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <div>{moment(row?.publishedDate).format("D, MMM YYYY")} </div>
      ),
    },
    { headerName: "Title", field: "title", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    {
      headerName: "Rank",
      field: "rank",
      flex: 1,
      renderCell: ({ row }: { row: any }) => {
        const [rankValue, setRankValue] = useState(row.rank || "");
        const handleRankChange = (value: string) => {
          setRankValue(value);
          setRankUpdates((prev) => ({
            ...prev,
            [row._id]: value === "" ? null : Number(value),
          }));
        };

        return isRankEdit ? (
          <TextField
            value={rankValue}
            onChange={(e) => handleRankChange(e.target.value)}
            placeholder="Enter Rank"
            variant="outlined"
            size="small"
            style={{ marginRight: "8px" }}
          />
        ) : (
          <Typography
            display="flex"
            alignItems="center"
            style={{ height: "100%" }}
          >
            {rankValue}
          </Typography>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <Box display="flex" alignItems="center">
          {hasPermissionPage("Blog", "update") && (
            <Tooltip title="Edit">
              <IconButton
                onClick={() => navigate(`/blogs/update/${row?._id}`)}
                aria-label="edit"
                style={{ marginRight: "8px", color: "#00C5B9" }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {hasPermissionPage("Blog", "delete") && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setDeleteBlogId(row._id);
                  setOpenDeleteModel(true);
                }}
                aria-label="delete"
                style={{ color: "#00C5B9" }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
    {
      headerName: "Verification",
      field: "Verification",
      width: 150,
      renderCell: ({ row }: any) => (
        <Box>
          {hasPermissionPage("Blog", "verify") && (
            <Tooltip title="Verify Blog">
              <IconButton
                onClick={() => setVerifyingBlog(row._id)}
                aria-label="status"
                style={{ color: "#869986" }}
              >
                <ApprovalIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_BLOGS, {
    variables: { page: pagination.page + 1, limit: pagination.pageSize },
  });

  const handleRowSelect = (id: any) => {
    setSelectedRows((prevSelectedRows: any) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId: any) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      const allRowIds = list.map((row: any) => row._id);
      setSelectedRows(allRowIds as any);
    } else {
      setSelectedRows([]);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultipleBlog({
        variables: {
          _id: selectedRows,
        },
      });
      if (res?.data?.deleteMultipleBlog?.statusCode === 200) {
        toast.success(res?.data?.deleteMultipleBlog?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultipleBlog?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };

  const handleAllDelete = async () => {
    try {
      const res = await DeleteAllBlogs();
      if (res?.data?.deleteAllBlogs?.statusCode === 200) {
        toast.success(res?.data?.deleteAllBlogs?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteAllBlogs?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteAllModel(false);
    }
  };


  useEffect(() => {
    if (data?.getBlogsAll?.data) {
      const sortedData = [...data?.getBlogsAll?.data]
        .filter((item) => item?.publishedDate)
        .sort(
          (a, b) =>
            new Date(b?.publishedDate).getTime() -
            new Date(a?.publishedDate).getTime()
        );

      setList(sortedData);
      setTotal(data?.getBlogsAll?.count || 0);
    }
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  const handleRefetch = (search?: string) => {
    refetch({
      variables: {
        page: pagination.page,
        limit: pagination.pageSize,
        search: search ?? searchTerm,
      },
    });
  };

  const handleDelete = async () => {
    if (!deleteBlogId) return;
    try {
      await deleteBlog({ variables: { _id: deleteBlogId } });
      setOpenDeleteModel(false);
      handleRefetch();
      toast.success("Blog deleted successfully.");
    } catch (error) {
      toast.error("Error deleting blog.");
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination({ page: newPage, pageSize: pagination.pageSize });
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  const handleRankUpdate = async () => {
    const input = Object.keys(rankUpdates).map((id) => ({
      blogId: id,
      rank: rankUpdates[id],
    }));

    try {
      const response = await UpdateBlogsRanksBulk({
        variables: { input: { blogs: input } },
      });
      if (response?.data?.updateBlogsRanksBulk?.statusCode === 200) {
        toast.success(response.data.updateBlogsRanksBulk.message);
        setRankUpdates({});
      } else {
        throw new Error(response.data.updateBlogsRanksBulk.message);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      handleRefetch();
    }
  };
  const handleVerify = async (id: string, status: string) => {
    try {
      const res = await VerifyBlog({
        variables: {
          input: {
            _id: id,
            status,
            reason,
          },
        },
      });

      if (res.errors) {
        throw new Error(
          `GraphQL error! message: ${res.errors
            .map((error) => error.message)
            .join(", ")}`
        );
      }

      handleRefetch();
      setReason("");
      setVerifyBlogValue("");
      toast.success(res?.data?.verifyBlog?.message);
      setVerifyingBlog(null);
    } catch (error) {
      console.error("Error verifying business:", error);
    }
  };
  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        paddingRight={4}
      >
        <Typography variant="h4" color="#00C5B9">
          Blog
        </Typography>

        <Box display="flex" alignItems="center" gap={2}>
          <ToggleSwitch mode={isRankEdit} setMode={setIsRankEdit} />
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: 200 }}
            InputProps={{
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ActionMenu
            isSuperAdmin={isSuperAdmin}
            setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
            setOpenDeleteAllModel={setOpenDeleteAllModel}
          />
          {isRankEdit && (
            <Tooltip title="Save">
              <Box width={50} mr={2}>
                <CustomButton
                  onClick={handleRankUpdate}
                  variant="contained"
                  sx={{ width: 50 }}
                >
                  <SaveIcon />
                </CustomButton>
              </Box>
            </Tooltip>
          )}
          {hasPermissionPage("Blog", "add") && (
            <Box>
              <CustomButton
                onClick={() => navigate(`${ROUTES.BLOGS_ADD}`)}
                variant="contained"
                className="width: 200px"
              >
                Create
              </CustomButton>
            </Box>
          )}
          {isSuperAdmin && (
            <ActionMenu
              isSuperAdmin={isSuperAdmin}
              setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
              setOpenDeleteAllModel={setOpenDeleteAllModel}
            />
          )}
          {isRankEdit && (
            <CustomButton
              onClick={handleRankUpdate}
              variant="contained"
              sx={{ width: 50 }}
            >
              <SaveIcon />
            </CustomButton>
          )}
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={pagination}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this blog?"
        />
      )}

      <VerifyEventsDialog
        label="Blog"
        handleVerify={handleVerify}
        setVerifyingData={setVerifyingBlog}
        verifyingData={verifyingBlog}
        verifyDataValue={verifyBlogValue}
        reason={reason}
        setVerifyDataValue={setVerifyBlogValue}
        setReason={setReason}
      />
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${selectedRows?.length ?? 0
            } blogs?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all blogs?"
        />
      )}
    </Box>
  );
};

export default Blog;

import { FormControl, Typography, Chip, Box } from "@mui/material";
import SubCategoryDropDown from "./SubCategoryDropDown";
import { useQuery } from "@apollo/client";
import { GET_ALL_SUBCATEGORIES } from "src/graphql/query";
import { AdditionalSubCategoriesProps } from "./Business";

const AdditionalSubCategories: React.FC<AdditionalSubCategoriesProps & { disabledOption?: string }> = ({
  values,
  setFieldValue,
  touched,
  errors,
  disabledOption,
}) => {
  const { data: subCategoryData } = useQuery(GET_ALL_SUBCATEGORIES, {
    notifyOnNetworkStatusChange: true,
  });

  const labelsData = subCategoryData?.getAllSubCategories?.data || [];

  const handleAddSubCategory = (value: any) => {
    if (value && !values.additionalSubCategories.includes(value?.value)) {
      setFieldValue("additionalSubCategories", [
        ...values?.additionalSubCategories,
        value?.value,
      ]);
    }
  };

  const handleRemoveSubCategory = (subCategory: string) => {
    setFieldValue(
      "additionalSubCategories",
      values?.additionalSubCategories.filter((item) => item !== subCategory)
    );
  };

  const filteredLabelsData = labelsData.filter(
    (data: any) => data?._id !== disabledOption
  );

  return (
    <FormControl
      style={{
        zIndex: "999",
      }}
      fullWidth
    >
      <Typography
        fontWeight={500}
        gutterBottom
        alignSelf={"start"}
        fontSize={17}
        marginX={1}
      >
        Additional Sub Categories
      </Typography>

      <SubCategoryDropDown
        handleSubCategoryChange={handleAddSubCategory}
        selectedSubCategory={null}
        disabledOptions={[values.primarySubCategory]}
      />

      {/* Display selected additional subcategories */}
      <Box mt={2}>
        {values?.additionalSubCategories.map((subCategory, index) => {
          const label = labelsData.find(
            (data: any) => data?._id === subCategory
          )?.subCategoryName;

          return (
            <Chip
              key={index}
              label={label || subCategory}
              onDelete={() => handleRemoveSubCategory(subCategory)}
              color="primary"
              style={{ margin: "4px" }}
            />
          );
        })}
      </Box>

      {touched.additionalSubCategories && errors.additionalSubCategories && (
        <Typography color="error">{errors.additionalSubCategories}</Typography>
      )}
    </FormControl>
  );
};

export default AdditionalSubCategories;

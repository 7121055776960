export const ROUTES = {
  HOME: "/home",
  LOGIN: "/login",
  USERS: "/users",
  USERS_DETAILS: "/users/:id",
  PINCODES: "/pincode",
  DEFAULT: "/events",
  EVENTS: "/events",
  EVENTS_DETAILS: "/events/:id",
  NEWS: "/news",
  NEWS_DETAILS: "/news/:id",
  BUSINESS: "/business",
  BUSSINESS_DETAILS: "/business/:id",
  BUSSINESS_CREATE: "/business/create",
  BUSSINESS_EDIT: "/business/edit/:id",
  BUSSINESS_SUPER_DETAILS: "/business/:id/moredetails",
  DEALS: "/deals",
  DEALS_DETAILS: "/deals/:id",
  CATEGORY: "/category",
  CATEGORY_DETAILS: "/category/:id",
  SUB_CATEGORY: "/subcategory/:id",
  SUB_CATEGORY_DETAILS: "/subcategory/:id",
  BLOGS: "/blogs",
  BLOGS_ADD: "/blogs/add",
  BLOGS_UPDATE: "/blogs/update/:id",
  BLOGS_DETAILS: "/blogs/:id",
  FAQ: "/faqs",
  ADVERTISE: "/advertise",
  CONTACT_US: "/contact-us",
  TAGS: "/tags",
  POPULAR_LOCATION: "/popularlocation",
  CATEGORY_LIST: "/category/:id",
  HOME_PAGE_SETTINGS: "/homepagesettings",
  TRACKING_DATA: "/tracking-data",
  REGION: "/region",
  CLAIM_BUSINESS: "/claimbusiness",
  DEAL_ENUM: "/deal-enum",
  EVENT_ENUM: "/event-enum",
  CONFIG: "/configdata",
  ENUM: "/enum",
  PERMISSION: "/permission",
  ADD_PERMISSION: "/permissions/add",
  UPDATE_PERMISSION: "/permissions/edit/:userId",
  HISTORY: "/history",
  HISTORY_ADD: "/history/add",
  HISTORY_UPDATE: "/history/update/:id",
  ABOUT_SURAT: "/aboutsurat",
  ABOUT_SURAT_ADD: "/aboutsurat/add",
  ABOUT_SURAT_UPDATE: "/aboutsurat/update/:id",
  SUB_CATEGORY_RANK: "/subcategoryrank",
  ADSLINK: "/adslink",
  ADSLINK_ADD: "/adslink/add",
  ADSLINK_UPDATE: "/adslink/update/:id",
  BUSINESSBYDATE : "/BusinessByDate"
};

export const PERMISSION_ROUTES_LIST: any = {
  HOME: "/home",
  LOGIN: "/login",
  USER: "/users",
  USERS_DETAILS: "/users/:id",
  PINCODE: "/pincode",
  DEFAULT: "/events",
  EVENT: "/events",
  EVENTS_DETAILS: "/events/:id",
  NEWS: "/news",
  NEWS_DETAILS: "/news/:id",
  BUSINESS: "/business",
  BUSSINESS_DETAILS: "/business/:id",
  BUSSINESS_CREATE: "/business/create",
  BUSSINESS_EDIT: "/business/edit/:id",
  BUSSINESS_SUPER_DETAILS: "/business/:id/moredetails",
  DEAL: "/deals",
  DEALS_DETAILS: "/deals/:id",
  CATEGORY: "/category",
  CATEGORY_DETAILS: "/category/:id",
  SUBCATEGORY: "/subcategory/:id",
  SUB_CATEGORY_DETAILS: "/subcategory/:id",
  BLOG: "/blogs",
  BLOGS_ADD: "/blogs/add",
  BLOGS_UPDATE: "/blogs/update/:id",
  BLOGS_DETAILS: "/blogs/:id",
  FAQ: "/faqs",
  ADSWITHUS: "/advertise",
  CONTACUTS: "/contact-us",
  TAG: "/tags",
  POPULARLOCATION: "/popularlocation",
  CATEGORY_LIST: "/category/:id",
  HOMEPAGESETTING: "/homepagesettings",
  TRACKINGDATA: "/tracking-data",
  REGION: "/region",
  CLAIMBUSINESS: "/claimbusiness",
  DEAL_ENUM: "/deal-enum",
  EVENT_ENUM: "/event-enum",
  CONFIGDATA: "/configdata",
  ENUM: "/enum",
  PRIVACY: "/permission",
  ADD_PERMISSION: "/permissions/add",
  UPDATE_PERMISSION: "/permissions/edit/:userId",
  DEALSENUM: "/enum",
  EVENTSENUM: "/enum",
  POPULARLOCATIONENUM: "/enum",
};

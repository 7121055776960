import * as Yup from "yup";
export enum Age {
  All = "All",
  English = "English",
  Gujarati = "Gujarati",
  Hindi = "Hindi",
  Marathi = "Marathi",
  Bengali = "Bengali",
  Kannada = "Kannada",
  Malayalam = "Malayalam",
  Tamil = "Tamil",
  Other = "Other",
}

export enum Language {
  All = "All",
  English = "English",
  Gujarati = "Gujarati",
  Hindi = "Hindi",
  Marathi = "Marathi",
  Bengali = "Bengali",
  Kannada = "Kannada",
  Malayalam = "Malayalam",
  Tamil = "Tamil",
  Other = "Other",
}

export enum EventCategory {
  All = "All",
  ComedyShows = "Comedy Shows",
  WorkShops = "WorkShops",
  Kids = "Kids",
  MusicShows_Concerts = "MusicShows Concerts",
  Performances = "Performances",
  Flea_Exhibitions = "Flea Exhibitions",
  Meetups = "Meetups",
}

export enum EventPlatform {
  All = "All",
  Online = "online",
  Offline = "offline",
}

export enum DateFilter {
  All = "All",
  Today = "Today",
  Tomorrow = "Tomorrow",
  This_Weekend = "This_Weekend",
  Upcoming = "Upcoming",
}

export enum PriceFilter {
  All = "All",
  Free = "Free",
  Range0_500 = "1-500",
  Range501_1000 = "501-1000",
  Range1001_2000 = "1001-2000",
  RangeAbove2000 = "Above 2000",
}

export enum VerificationStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Rejected = "REJECTED",
}

// Display Name Mappings
const languageDisplayNames = {
  [Age.All]: "All",
  [Age.English]: "English",
  [Age.Gujarati]: "Gujarati",
  [Age.Hindi]: "Hindi",
  [Age.Marathi]: "Marathi",
  [Age.Bengali]: "Bengali",
  [Age.Kannada]: "Kannada",
  [Age.Malayalam]: "Malayalam",
  [Age.Tamil]: "Tamil",
  [Age.Other]: "Other",
};

const eventCategoryDisplayNames = {
  [EventCategory.All]: "All",
  [EventCategory.ComedyShows]: "Comedy Shows",
  [EventCategory.WorkShops]: "Workshops",
  [EventCategory.Kids]: "Kids",
  [EventCategory.MusicShows_Concerts]: "Music Shows & Concerts",
  [EventCategory.Performances]: "Performances",
  [EventCategory.Flea_Exhibitions]: "Flea & Exhibitions",
  [EventCategory.Meetups]: "Meetups",
};

const eventPlatformDisplayNames = {
  [EventPlatform.All]: "All",
  [EventPlatform.Online]: "Online",
  [EventPlatform.Offline]: "Offline",
};

const dateFilterDisplayNames = {
  [DateFilter.All]: "All",
  [DateFilter.Today]: "Today",
  [DateFilter.Tomorrow]: "Tomorrow",
  [DateFilter.This_Weekend]: "This Weekend",
  [DateFilter.Upcoming]: "Upcoming",
};

const priceFilterDisplayNames = {
  [PriceFilter.All]: "All",
  [PriceFilter.Free]: "Free",
  [PriceFilter.Range0_500]: "₹0 - ₹500",
  [PriceFilter.Range501_1000]: "₹501 - ₹1000",
  [PriceFilter.Range1001_2000]: "₹1001 - ₹2000",
  [PriceFilter.RangeAbove2000]: "Above ₹2000",
};

const verificationStatusDisplayNames = {
  [VerificationStatus.Pending]: "Pending",
  [VerificationStatus.Approved]: "Approved",
  [VerificationStatus.Rejected]: "Rejected",
};

// Options Generation
export const languageOptions = Object.keys(Age).map((key) => ({
  value: Age[key as keyof typeof Age],
  label: languageDisplayNames[Age[key as keyof typeof Age]],
}));

export const eventCategoryOptions = Object.keys(EventCategory).map((key) => ({
  value: EventCategory[key as keyof typeof EventCategory],
  label:
    eventCategoryDisplayNames[EventCategory[key as keyof typeof EventCategory]],
}));

export const eventPlatformOptions = Object.keys(EventPlatform).map((key) => ({
  value: EventPlatform[key as keyof typeof EventPlatform],
  label:
    eventPlatformDisplayNames[EventPlatform[key as keyof typeof EventPlatform]],
}));

export const dateFilterOptions = Object.keys(DateFilter).map((key) => ({
  value: DateFilter[key as keyof typeof DateFilter],
  label: dateFilterDisplayNames[DateFilter[key as keyof typeof DateFilter]],
}));

export const priceFilterOptions = Object.keys(PriceFilter).map((key) => ({
  value: PriceFilter[key as keyof typeof PriceFilter],
  label: priceFilterDisplayNames[PriceFilter[key as keyof typeof PriceFilter]],
}));

export const verificationStatusOptions = Object.keys(VerificationStatus).map(
  (key) => ({
    value: VerificationStatus[key as keyof typeof VerificationStatus],
    label:
      verificationStatusDisplayNames[
        VerificationStatus[key as keyof typeof VerificationStatus]
      ],
  })
);

export interface ContactDetail {
  name: string;
  mobileNumber: string;
}
export interface EventData {
  _id: string;
  desktopImage: string;
  image?: string;
  title?: string;
  venue?: string;
  startDate?: string;
  endDate?: string;
  price?: any;
  eventBy?: string;
  description?: string;
  contactDetails: ContactDetail[];
  pincode: string;
  startTime: string;
  endTime: string;
  showOnWeb: string;
  sourceType: string;
  type: string;
  language: string;
  artist: string;
  category: string;
  status: string;
  verifiedBy: string;
  verificationDate: string;
  reason: string;
  bookingPartner: string;
  rank: string;
  slug: string;
  metaTitle: string;
  metaDescription: string;
}

export interface EventPageProps {
  data: EventData;
  onClose: () => void;
}

export interface EventsPageProps {
  events?: any;
  refetchEvents: () => void;
  handleClose: () => void;
}

export const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  startTime: Yup.string().required("Start Time is required"),
  endTime: Yup.string().required("End Time is required"),
  type: Yup.string().required("Type is required"),
  startDate: Yup.string().required("Start Date is required"),
  endDate: Yup.string().required("End Date is required"),
  // venue: Yup.string().required("Venue is required"),
  language: Yup.string().required("Language is required"),
  bookingPartner: Yup.string().required("Booking Partner is required"),
});

import { Box, FormControl, Typography } from "@mui/material";
import React, { useState } from "react";
import Select, { SingleValue } from "react-select";

// Define types for props
interface BusinessOption {
  value: string;
  label: string;
}

interface BusinessSelectDropDownProps {
  selectedBusiness: string | null;
  handleBusinessChange: (selectedOption: SingleValue<BusinessOption>) => void;
  businessOptions: BusinessOption[];
  setSearchTerm?: (term: string) => void;
}

const BusinessSelectDropDown: React.FC<BusinessSelectDropDownProps> = ({
  selectedBusiness,
  handleBusinessChange,
  businessOptions,
  setSearchTerm,
}) => {
  const [filteredOptions, setFilteredOptions] = useState<BusinessOption[]>([]);
  const handleInputChange = (inputValue: string) => {
    setSearchTerm && setSearchTerm(inputValue);

    if (inputValue.length >= 3) {
      const filtered = businessOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions([]);
    }
    return inputValue;
  };

  const onChange = (selectedOption: SingleValue<BusinessOption>) => {
    handleBusinessChange(selectedOption);

    if (!selectedOption && setSearchTerm) {
      setSearchTerm("");
    }
  };
  const id = businessOptions.find(
    (option) => option.value === selectedBusiness
  );

  return (
    <FormControl fullWidth sx={{ zIndex: "9999" }}>
      <Select
        name="businessProfileId"
        value={
          businessOptions.find((option) => option.value === selectedBusiness) ||
          null
        }
        maxMenuHeight={300}
        placeholder="Select Business"
        options={filteredOptions}
        onChange={onChange}
        onInputChange={handleInputChange}
        isClearable
        openMenuOnClick
      />
      {id && (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (window) {
              window.open(`/business/${id?.value}`, "_blank");
            }
          }}
        >
          <Box display={"flex"} gap={1} alignItems={"center"} mb={1}>
            <Typography variant="body2" color="textSecondary">
              View this business {id?.label}
            </Typography>
          </Box>
        </Box>
      )}
    </FormControl>
  );
};

export default BusinessSelectDropDown;

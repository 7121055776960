import React from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Chip,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface DealDetailPageProps {
  dealData: any;
  onClose: () => void;
}

const DealDetailPage: React.FC<DealDetailPageProps> = ({
  dealData,
  onClose,
}) => {
  if (!dealData) {
    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: "1500px",
          margin: "auto",
          mt: "10%",
          p: 3,
          textAlign: "center",
          borderRadius: 2,
          boxShadow: 3,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant="h5" color="primary" fontWeight="bold" mb={2}>
          Deal Details
        </Typography>
        <Typography variant="body1" color="textSecondary">
          No deal data available.
        </Typography>
        <Button
          onClick={onClose}
          variant="outlined"
          color="primary"
          sx={{ mt: 3 }}
        >
          Go Back
        </Button>
      </Box>
    );
  }

  const {
    title,
    category,
    subCategoryId,
    area,
    offer,
    offerType,
    description,
    termsAndConditions,
    duration,
    discountBracket,
    repeatDuration,
    image,
    businessProfile,
    slug,
    clickCount,
    activityStatus,
    deleted,
    metaTitle,
    metaDescription,
  } = dealData;

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1200px",
        margin: "auto",
        mt: 5,
        p: 3,
        borderRadius: 2,
        bgcolor: "background.paper",
        boxShadow: 3,
      }}
    >
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" color="primary" fontWeight="bold">
          Deal Detail
        </Typography>
        <IconButton onClick={onClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      </Box>

      <Grid container spacing={3}>
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <CardContent>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Slug:</strong> {slug}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Category:</strong> {category}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Subcategory ID:</strong> {subCategoryId}
            </Typography>
            <Chip
              label={offer}
              color="primary"
              sx={{ mt: 2, fontSize: "0.9rem", fontWeight: "bold" }}
            />
            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
              <strong>Offer Type:</strong> {offerType}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Description:</strong> {description}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
              <strong>Area:</strong> {area}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Terms & Conditions:</strong> {termsAndConditions}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Duration:</strong>{" "}
              {new Date(duration?.startDate).toLocaleDateString()} -{" "}
              {new Date(duration?.endDate).toLocaleDateString()}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Discount Bracket:</strong> {discountBracket?.minimum}% -{" "}
              {discountBracket?.maximum}%
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Repeat:</strong> {repeatDuration?.repeat}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Business:</strong> {businessProfile?.businessName}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Click Count:</strong> {clickCount}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Activity Status:</strong> {activityStatus}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Deleted:</strong> {deleted ? "Yes" : "No"}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Meta Title:</strong> {metaTitle}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Meta Description:</strong> {metaDescription}
            </Typography>
          </CardContent>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            image={image || "https://via.placeholder.com/500x300"}
            alt="Deal Image"
            sx={{
              width: "100%",
              height: "auto",
              borderRadius: 2,
            }}
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Box display="flex" justifyContent="flex-end">
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default DealDetailPage;

import React, { useState } from "react";
import {
  Box,
  CardMedia,
  Grid,
  Modal,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ImageData {
  imageUrl: string;
}

interface ImageGalleryProps {
  galleries: ImageData[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ galleries }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      <Grid container spacing={3}>
        {galleries?.map((gallery, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <CardMedia
              component="img"
              height="250"
              image={gallery?.imageUrl}
              alt={`Gallery image ${index + 1}`}
              sx={{
                borderRadius: 2,
                boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
                transition: "transform 0.3s",
                cursor: "pointer",
                "&:hover": { transform: "scale(1.03)" },
              }}
              onClick={() => handleImageClick(gallery?.imageUrl)}
            />
          </Grid>
        ))}
      </Grid>

      {/* Modal for displaying the clicked image */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            outline: 0,
            borderRadius: 3,
            width: "90%",
            maxWidth: 800,
            maxHeight: "90vh",
            overflowY: "auto",
            p: 2,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                color: "#fff",
                backgroundColor: "#1976d2",
                "&:hover": { backgroundColor: "#155b9f" },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {selectedImage && (
            <CardMedia
              component="img"
              image={selectedImage}
              alt="Selected gallery image"
              sx={{
                width: "100%",
                height: "auto",
                maxHeight: "700px",
                borderRadius: 2,
              }}
            />
          )}
          <Typography
            variant="body2"
            sx={{ mt: 2, textAlign: "center", color: "#555" }}
          >
            Image {galleries?.findIndex((g) => g.imageUrl === selectedImage) + 1}{" "}
            of {galleries?.length}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default ImageGallery;

import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Select from "react-select";
import CustomButton from "src/components/CustomButton";
import CustomTable from "src/components/CustomTable";
import ClearIcon from "@mui/icons-material/Clear";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "src/utils/debounce";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ADSLINK } from "src/graphql/query";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import DeleteModel from "src/components/Common/DeleteModel";
import { toast } from "react-toastify";

import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import { createInitialsImage } from "../Region/BusinessCard";
import { DELETE_ADS_LINK } from "src/graphql/DeleteMutation";
import { useLocation, useNavigate } from "react-router";
import { ROUTES } from "src/constant";

function AdsLinkDataList() {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [entityTypes, setEntityTypes] = useState<string[]>([]);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 25 });
  const [total, setTotal] = useState(0);
  const [list, setList] = useState<any>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const location = useLocation();
  const state = location.state as { refetch?: boolean } | null;
  const [ToDelete, setToDelete] = useState<any>(null);
  const [entityTypeList, setEntityTypeList] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const [DeleteAdsLink] = useMutation(DELETE_ADS_LINK);
  const navigate = useNavigate();
  const isSuperAdmin = getRole() === "SuperAdmin";

  const COLUMNS = [
    {
      headerName: "Iamge and Title",
      field: "title",
      width: 350,
      renderCell: ({ row }: any) => (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          sx={{ cursor: "pointer" }}
        >
          {row.imageUrl ? (
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <img
                src={row?.imageUrl ?? createInitialsImage(row?.title)}
                alt={row.imageUrl}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  margin: 4,
                }}
              />
              <Typography variant="body2" color="textSecondary">
                {row?.title}
              </Typography>
            </Box>
          ) : (
            <Typography variant="body2" color="textSecondary" mt={2}>
              {row?.title}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      headerName: "Entity Type",
      field: "entityType",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Typography variant="body2" color="textSecondary">
          {row?.entityType?.join(", ")}
        </Typography>
      ),
    },
    { headerName: "Description", field: "description", flex: 1 },

    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <Box>
          <IconButton
            onClick={() => navigate(`/adslink/update/${row?._id}`)}
            aria-label="edit"
            style={{ marginRight: "8px", color: "#00C5B9" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(row)}
            aria-label="delete"
            style={{ color: "#00C5B9" }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleDelete = (rowData: any) => {
    setToDelete(rowData);
    setOpenConfirmDialog(true);
  };

  const { loading, error, data, refetch } = useQuery(GET_ADSLINK, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
      search: searchTerm,
      type: entityTypes,
    },
  });

  useEffect(() => {
    if (data?.getAllAdsLinks?.data) {
      setList(data?.getAllAdsLinks?.data);
      setTotal(data?.getAllAdsLinks?.count);
      const uniqueEntityTypes = data?.getAllAdsLinks?.data
        ?.flatMap((item: any) => item?.entityType || [])
        .filter(
          (value: any, index: any, self: any) => self.indexOf(value) === index
        );
      setEntityTypeList(
        uniqueEntityTypes?.map((item: any) => ({ label: item, value: item })) ||
          []
      );
    }
  }, [data, refetch]);

  const handleEntityTypeChange = (selectedOptions: any) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option: any) => option.value)
      : [];
    setSelectedCategories(selectedValues);
    setEntityTypes(selectedValues);
    refetch({
      search: searchTerm,
      page: 1,
      limit: pagination.pageSize,
      entityTypes: selectedValues,
    });
  };

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      refetch({
        search: term,
        page: 1,
        limit: pagination.pageSize,
        entityTypes: entityTypes,
      });
    }, 1000),
    [refetch, pagination.pageSize, entityTypes]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
    debouncedRefetch(value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({
      search: "",
      page: 1,
      limit: pagination.pageSize,
      entityTypes: entityTypes,
    });
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
  };

  const handleConfirmDelete = async () => {
    try {
      if (ToDelete) {
        const res = await DeleteAdsLink({
          variables: { id: ToDelete._id },
        });
        if (res?.data?.deleteAdsLink?.statusCode === 200) {
          toast.success(res?.data?.deleteAdsLink?.message);
          setToDelete(null);
          setOpenConfirmDialog(false);
          refetch();
        } else {
          throw new Error(res?.data?.deleteAdsLink?.message);
        }
      }
    } catch (error: any) {
      toast.error(
        error?.message || "An error occurred while deleting the ads link."
      );
    }
  };

  useEffect(() => {
    if (state?.refetch) {
      refetch();
      window.history.replaceState(
        null,
        document.title,
        window.location.pathname
      );
    }
  }, [state, refetch]);

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        paddingRight={4}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Ads Link
        </Typography>

        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box width={200}>
            <Select
              value={entityTypeList.filter((option) =>
                selectedCategories.includes(option.value)
              )}
              options={entityTypeList}
              onChange={handleEntityTypeChange}
              isMulti
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              placeholder="EntityType"
            />
          </Box>

          {hasPermissionPage("AdsLink", "add") && (
            <Box>
              <CustomButton
                onClick={() =>
                  navigate(`${ROUTES.ADSLINK_ADD}`, {
                    state: { refetch: true },
                  })
                }
                variant="contained"
                className="width: 200px"
              >
                Create
              </CustomButton>
            </Box>
          )}
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      <DeleteModel
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this AdsLink?"
      />
    </Box>
  );
}

export default AdsLinkDataList;

import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomButton from "src/components/CustomButton";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_ADS_LINK, UPDATE_ADS_LINK } from "src/graphql/mutations";
import { useParams, useNavigate } from "react-router-dom";
import { GET_ADSLINK_BY_ID } from "src/graphql/query";
import styles from "./AdsLink.module.css";
import { uploadImage } from "src/components/Common/Utils";
import CloseIcon from "@mui/icons-material/Close";
import "react-datepicker/dist/react-datepicker.css";
import RichTextEditor from "src/components/Common/TextEditor";
import SubCategoryDropDown from "../Business/SubCategoryDropDown";
import backButton from "../../Images/backButton.svg";

const ROUTES = {
  ADSLINK: "/adslink",
  ADSLINK_ADD: "/adslink/add",
  ADSLINK_UPDATE: "/adslink/update/:id",
};

const AdsLinkModal: React.FC = () => {
  const { pathname } = window.location;
  const isEdit = pathname.startsWith("/adslink/update");
  const { id } = useParams();
  const navigate = useNavigate();
  const [mainImageFile, setMainImageFile] = useState<File | null>(null);
  const [mainImageUrl, setMainImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [AddAdLink] = useMutation(ADD_ADS_LINK);
  const [UpdateAdLink] = useMutation(UPDATE_ADS_LINK);
  type EntityType = "BLOG" | "DEALS" | "BUSINESS" | "EVENT" | "POPULARLOCATION";

  const ENTITY_TYPES: EntityType[] = [
    "BLOG",
    "DEALS",
    "BUSINESS",
    "EVENT",
    "POPULARLOCATION",
  ];
  const { data: adslinkData } = useQuery(GET_ADSLINK_BY_ID, {
    variables: { id: id },
    skip: !isEdit || !id,
    onCompleted: (data) => {
      if (data?.getAdsLinkById?.data) {
        const {
          imageUrl,
          title,
          description,
          redirectLink,
          subCategoryId,
          entityType,
        } = data?.getAdsLinkById?.data;
        setMainImageUrl(imageUrl);

        formik.setValues({
          title,
          description,
          imageUrl,
          redirectLink,
          subCategoryId,
          entityType: entityType || [],
        });
        setLoading(false);
      }
    },
    onError: () => {
      toast.error("Failed to fetch AdsLink details.");
      setLoading(false);
    },
  });

  useEffect(() => {
    if (pathname === ROUTES.ADSLINK_ADD) {
      setLoading(false);
    }
  }, [pathname]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description should be at least 10 characters long"),
    redirectLink: Yup.string().required("Redirect Link is required"),
  });
  const formik = useFormik({
    initialValues: {
      imageUrl: "",
      redirectLink: "",
      title: "",
      description: "",
      subCategoryId: "",
      entityType: [] as EntityType[],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let historyMainImageUrl = mainImageUrl;
      if (mainImageFile) {
        const res = await uploadImage(mainImageFile, "adslink", values.title);
        historyMainImageUrl = res?.uploadImage?.url;
      }
      setLoading(true);
      try {
        const input: Record<string, any> = {
          ...values,
          imageUrl: historyMainImageUrl,
          entityType: values.entityType,
        };

        if (!values.subCategoryId) {
          delete input.subCategoryId;
        }
        const variables = {
          id: isEdit ? id : undefined,
          input,
        };
        const mutation = isEdit ? UpdateAdLink : AddAdLink;
        await mutation({ variables });

        toast.success(
          isEdit
            ? "AdsLink updated successfully!"
            : "AdsLink added successfully!"
        );
        navigate("/adslink", { state: { refetch: true } });
      } catch (error: any) {
        toast.error(
          error.message || "An error occurred while saving the AdsLink."
        );
      } finally {
        setLoading(false);
      }
    },
  });

  const handleMainImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setMainImageFile(file);
      setMainImageUrl(URL.createObjectURL(file));
    }
  };

  return (
    <Box overflow={"scroll"} paddingX={20}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <img
          src={backButton}
          alt="backButton"
          height={25}
          width={25}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={() => navigate(`${ROUTES.ADSLINK}`)}
        />
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: "#00C5B9" }}
          marginTop={2}
        >
          {isEdit ? "Update AdsLink" : "Create AdsLink"}
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && (formik.errors.title as string)}
          />
          <Box mb={3}>
            <Typography>Description:</Typography>
            <RichTextEditor
              value={formik.values.description}
              onChange={(content: string) =>
                formik.setFieldValue("description", content)
              }
              className={styles.descriptionWrapper}
            />
            {formik.touched.description && formik.errors.description && (
              <Typography color="error">{formik.errors.description}</Typography>
            )}
          </Box>
          <TextField
            fullWidth
            label="Redirect Link"
            variant="outlined"
            name="redirectLink"
            value={formik.values.redirectLink}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.redirectLink && Boolean(formik.errors.redirectLink)
            }
            helperText={
              formik.touched.redirectLink &&
              (formik.errors.redirectLink as string)
            }
          />
          <Box>
            <Typography
              gutterBottom
              fontWeight={500}
              alignSelf={"start"}
              fontSize={17}
              marginX={1}
            >
              Sub Category
            </Typography>
            <SubCategoryDropDown
              handleSubCategoryChange={(value) =>
                formik.setFieldValue("subCategoryId", value?.value)
              }
              selectedSubCategory={formik.values.subCategoryId}
            />
          </Box>

          <Box>
            <Typography
              gutterBottom
              fontWeight={500}
              alignSelf={"start"}
              fontSize={17}
              marginX={1}
            >
              Entity Types
            </Typography>
            <FormGroup>
              {ENTITY_TYPES.map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <Checkbox
                      checked={formik.values.entityType.includes(type)}
                      onChange={(e) => {
                        const updatedEntityTypes = e.target.checked
                          ? [...formik.values.entityType, type]
                          : formik.values.entityType.filter(
                              (t: any) => t !== type
                            );
                        formik.setFieldValue("entityType", updatedEntityTypes);
                      }}
                    />
                  }
                  label={type}
                />
              ))}
            </FormGroup>
            {formik.touched.entityType && formik.errors.entityType && (
              <Typography color="error">{formik.errors.entityType}</Typography>
            )}
          </Box>

          <Box className={styles.imageContainer}>
            <input
              type="file"
              onChange={handleMainImageChange}
              style={{ display: "none" }}
              id="blog-image-upload"
            />
            <label htmlFor="blog-image-upload">
              {mainImageUrl ? (
                <>
                  <img
                    src={mainImageUrl}
                    alt="AdsLink"
                    className={styles.imagePreview}
                  />
                  <IconButton
                    className={styles.closeIcon}
                    onClick={() => {
                      setMainImageUrl(null);
                      setMainImageFile(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <Typography color="primary">Upload Image</Typography>
              )}
            </label>
          </Box>

          <Box>
            <CustomButton type="submit">
              {isEdit ? "Update AdsLink" : "Add AdsLink"}
            </CustomButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AdsLinkModal;

import React, { useEffect, useState } from "react";
import CheckText from "./CheckText";
import OverviewLabel from "./OverviewLabel";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from "@mui/material";
import CustomButton from "src/components/CustomButton";

const WrapperComponent: React.FC<{
  children: React.ReactNode;
  heading?: string;
}> = ({ children, heading }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 2,
      padding: 2,
    }}
  >
    {heading && (
      <Typography
        sx={{ fontSize: "1.2rem", color: "#030712", textAlign: "center" }}
      >
        {heading}
      </Typography>
    )}
    <Typography
      sx={{ fontSize: "1.2rem", color: "#030712", textAlign: "center" }}
    >
      {children}
    </Typography>
  </Box>
);

const Overview: React.FC<any> = ({ overview }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      {overview?.[0]?.dynamicOptions?.length > 0 && (
        <WrapperComponent heading="Services">
          <OverviewLabel checkTextData={overview?.[0]?.dynamicOptions} />
          <CustomButton
            variant="outlined"
            onClick={handleOpenModal}
            sx={{
              bgcolor: "#E5E7EB",
              color: "#141414",
              fontSize: "16px",
              textTransform: "none",
              fontWeight: 600,
              width: "100%",
              "&:hover": {
                bgcolor: "#D1D5DB",
              },
            }}
          >
            View more
          </CustomButton>
        </WrapperComponent>
      )}

      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
        sx={{ marginY: 20 }}
      >
        <DialogTitle>
          <Typography
            variant="body2"
            sx={{ fontSize: "1.2rem", color: "#030712", textAlign: "center" }}
          >
            Services
          </Typography>
        </DialogTitle>
        <DialogContent>
          <CheckText checkTextData={overview?.[0]?.dynamicOptions} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseModal} color="primary">
            Close
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Overview;

import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import Logo from "../../../assests/images/logo.png";
import { useNavigate } from "react-router";
import {
  removeENV,
  removeRole,
  removeToken,
} from "src/components/Common/Utils";
import { ROUTES } from "src/constant";
import { persistor } from "src/store/store";
import { useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";

const Header = ({ isOpen = false, toggleSidebar = () => {} }: any) => {
  const settings = ["Profile", "Account", "Dashboard", "Logout"];
  const userDataSelector = useSelector((state: any) => state.authReducer);

  const user = userDataSelector.users;
  const { firstName, lastName } = user?.user || {};
  const userName = firstName.toUpperCase()[0] + lastName.toUpperCase()[0];

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const navigate = useNavigate();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSetting = (setting: any) => {
    if (setting === "Logout") {
      removeRole();
      removeToken();
      removeENV();
      localStorage.removeItem("permissionRoute");
      persistor.purge();
      setTimeout(() => {
        navigate(ROUTES.LOGIN);
      }, 1000);
      window.location.reload();
    }
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: 99999999,
        backgroundColor: "white",
        color: "black",
      }}
    >
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          {/* Clipped drawer */}
          {!isOpen && (
            <MenuIcon
              style={{
                marginRight: "10px",
                cursor: "pointer",
                transition: "width 0.3s ease",
              }}
              onClick={toggleSidebar}
            />
          )}
          <img src={Logo} alt="Logo" style={{ height: "30px" }} />
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 0 }}>
          <IconButton
            onClick={handleOpenUserMenu}
            sx={{
              p: 1,
              background: "#bdbdbd",
              "&:hover": {
                backgroundColor: "#bdbdbd",
              },
            }}
          >
            {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
            <span style={{ color: "#fff" }}>{userName}</span>
          </IconButton>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography
                  textAlign="center"
                  onClick={() => handleSetting(setting)}
                >
                  {setting}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_USERS, GET_PRIVACY } from "src/graphql/query";
import { ADD_PRIVACY, UPDATE_PRIVACY } from "src/graphql/mutations"; // Import mutations
import { toast } from "react-toastify";
import CustomButton from "src/components/CustomButton";

const allPages = [
  "Business",
  "Blog",
  "Event",
  "PopularLocation",
  "Deals",
  "Category",
  "SubCategory",
  "Faq",
  "Tags",
  "User",
  "PinCode",
  "ClaimBusiness",
  "AdsWithUs",
  "ContactUs",
  "HomePageSetting",
  "TrackingData",
  "DealsEnum",
  "EventsEnum",
  "PopularLocationEnum",
  "ConfigData",
  "Privacy",
  "Permission",
  "History",
  "AboutSurat",
  "SubcategoryRank",
  "AdsLink"
];
const AddEditPermissionPage: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [permissions, setPermissions] = useState<any>({});
  const { userId } = useParams();
  const isEdit = Boolean(userId);
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState<string | undefined>(
    userId ?? ""
  );

  // Fetch users for the dropdown
  const { loading: usersLoading, data: usersData } = useQuery(GET_ALL_USERS, {
    variables: { role: "Admin" },
  });

  // Fetch user permissions for editing
  const { loading: permissionsLoading, data: permissionsData } = useQuery(
    GET_PRIVACY,
    {
      variables: { _id: userId },
      skip: !isEdit, // Skip query if it's not Edit mode
    }
  );

  // Mutation to add privacy
  const [addPrivacy] = useMutation(ADD_PRIVACY);

  // Mutation to update privacy
  const [updatePrivacy] = useMutation(UPDATE_PRIVACY);

  useEffect(() => {
    if (usersData?.getAllUsers?.data) {
      setUsers(usersData.getAllUsers.data);
    }
  }, [usersData]);

  useEffect(() => {
    if (permissionsData?.getPrivacy?.data) {
      const fetchedPermissions =
        permissionsData.getPrivacy.data.permissions.reduce(
          (acc: any, perm: any) => {
            acc[perm.type] = perm;
            return acc;
          },
          {}
        );
      setPermissions(fetchedPermissions);
    }
  }, [permissionsData]);

  const handlePermissionChange = (
    page: string,
    action: string,
    value: boolean
  ) => {
    setPermissions((prev: any) => {
      const updatedPermissions = {
        ...prev,
        [page]: {
          ...prev[page],
          [action]: value,
        },
      };

      if (value && action !== "view" && !updatedPermissions[page]?.view) {
        updatedPermissions[page].view = true;
      }

      return updatedPermissions;
    });
  };

  const handleSelectAll = (field?: string, value?: boolean) => {
    setPermissions((prev: any) =>
      allPages.reduce((acc: any, page: string) => {
        acc[page] = {
          ...prev[page],
          ...(field
            ? { [field]: value } // Field-specific select all
            : {
                view: value,
                update: value,
                verify: value,
                delete: value,
                add: value,
              }), // Universal select all
        };
        return acc;
      }, {})
    );
  };

  const handlePageSelectAll = (page: string, value: boolean) => {
    setPermissions((prev: any) => ({
      ...prev,
      [page]: {
        view: value,
        update: value,
        verify: value,
        delete: value,
        add: value,
      },
    }));
  };

  const handleSave = async () => {
    const permissionsInput = Object?.keys(permissions).map((page) => ({
      type: page,
      view: permissions[page]?.view || false,
      update: permissions[page]?.update || false,
      verify: permissions[page]?.verify || false,
      delete: permissions[page]?.delete || false,
      add: permissions[page]?.add || false,
    }));
    if (isEdit) {
      try {
        const res = await updatePrivacy({
          variables: {
            _id: selectedUser ?? "",
            input: {
              permissions: permissionsInput,
            },
          },
        });
        if (res?.data?.updatePrivacy?.statusCode === 200) {
          toast?.success(res?.data?.updatePrivacy?.message);
          navigate("/permission");
        } else {
          throw new Error(res?.data?.updatePrivacy?.message);
        }
      } catch (error: any) {
        console.error(error);
        toast.error(error?.message);
      }
    } else {
      try {
        const res = await addPrivacy({
          variables: {
            input: {
              userId: selectedUser,
              permissions: permissionsInput,
            },
          },
        });
        if (res?.data?.addPrivacy?.statusCode === 200) {
          toast?.success(res?.data?.addPrivacy?.message);
          navigate("/permission");
        } else {
          throw new Error(res?.data?.addPrivacy?.message);
        }
      } catch (error: any) {
        console.error(error);
        toast.error(error?.message);
      }
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        {isEdit ? "Edit" : "Add"} User Permissions
      </Typography>
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
        {!isEdit && (
          <Box mb={3}>
            <Typography>Select User</Typography>
            <Select
              value={selectedUser ?? ""}
              onChange={(e) => setSelectedUser(e.target.value)}
              fullWidth
            >
              {users.map((user: any) => (
                <MenuItem key={user._id} value={user._id}>
                  {user.firstName} {user.lastName} ({user?.email})
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}

        {/* Permissions Table */}
        <TableContainer
          component={Paper}
          elevation={1}
          sx={{
            maxHeight: 700,
            overflowY: "auto",
            position: "relative",
          }}
        >
          <Table stickyHeader sx={{ position: "relative" }}>
            {" "}
            <TableHead
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <TableRow>
                <TableCell>Page</TableCell>
                {["View", "Update", "Verify", "Delete", "Add"].map((action) => (
                  <TableCell key={action} align="center">
                    {action}
                    <Checkbox
                      checked={allPages.every(
                        (page) => permissions[page]?.[action.toLowerCase()]
                      )}
                      onChange={(e) =>
                        handleSelectAll(action.toLowerCase(), e.target.checked)
                      }
                    />
                  </TableCell>
                ))}
                <TableCell align="center">Select All (Page-Wise)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  Universal Select All
                  <Checkbox
                    checked={allPages.every((page) =>
                      ["view", "update", "verify", "delete", "add"].every(
                        (action) => permissions[page]?.[action]
                      )
                    )}
                    onChange={(e) =>
                      handleSelectAll(undefined, e.target.checked)
                    }
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allPages.map((page) => (
                <TableRow key={page}>
                  <TableCell>{page}</TableCell>
                  {["view", "update", "verify", "delete", "add"].map(
                    (action) => (
                      <TableCell key={action} align="center">
                        <Checkbox
                          checked={permissions[page]?.[action] || false}
                          onChange={(e) =>
                            handlePermissionChange(
                              page,
                              action,
                              e.target.checked
                            )
                          }
                        />
                      </TableCell>
                    )
                  )}
                  <TableCell align="center">
                    <Checkbox
                      checked={[
                        "view",
                        "update",
                        "verify",
                        "delete",
                        "add",
                      ].every((action) => permissions[page]?.[action])}
                      onChange={(e) =>
                        handlePageSelectAll(page, e.target.checked)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Save Button */}
        <Box mt={3}>
          <CustomButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSave}
            sx={{
              backgroundColor: "#1976d2",
              "&:hover": {
                backgroundColor: "#115293",
              },
            }}
          >
            Save Permissions
          </CustomButton>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddEditPermissionPage;

import { toast } from "react-toastify";
import Cookie from "js-cookie";
import { PERMISSION_ROUTES_LIST, ROUTES } from "src/constant";
import Cookies from "js-cookie";
import urlSlug from "url-slug";

export interface PincodeOption {
  value: string;
  label: string;
}



export const getToken = () => {
  return Cookie.get("accessToken");
};

export const setToken = (token: string) => {
  return Cookie.set("accessToken", token, {
    secure: true,
    sameSite: "Strict",
    expires: 1,
  });
};

export const removeToken = () => {
  Cookie.remove("permissions");
  return Cookie.remove("accessToken");
};

export const removeENV = () => {
  return Cookie.remove("isEnvAdmin");
};

export const getRole = (): string => {
  try {
    return Cookies.get("role") || "";
  } catch {
    return "";
  }
};

export const getIsEnvAdmin = (): boolean => {
  try {
    return Cookies.get("isEnvAdmin") === "true";
  } catch {
    return false;
  }
};

export const removeRole = () => {
  return Cookie.remove("role");
};
const url = process.env.REACT_APP_GRAPHQL_ENDPOINT ?? "";

// Reusable function to handle image uploads (single or multiple)
const uploadToServer = async (formData: FormData, operationName: string) => {
  try {
    const token = getToken()?.replace(/^"|"$/g, "");
    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        "x-apollo-operation-name": operationName,
        "apollo-require-preflight": "true",
        authorization: `Bearer ${token} `,
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${errorText}`
      );
    }

    const { data } = await response.json();
    return data;
  } catch (error) {
    console.error(`Error in ${operationName}:`, error);
    toast.error(`Error uploading images for ${operationName}.`);
    throw error;
  }
};

// For multiple image upload
export const uploadImages = async (
  imagesData: File[],
  folder: string,
  id: string
) => {
  const formData = new FormData();
  const slugBase = urlSlug(id?.toLowerCase().trim(), {
    separator: "-",
  });
  formData.append(
    "operations",
    JSON.stringify({
      query: `
        mutation ($files: [Upload!]!, $folder: String, $id: String) {
          uploadMultipleImages(files: $files, folder: $folder, id: $id) {
            encoding
            url
            filename
            mimetype
          }
        }
      `,
      variables: {
        files: new Array(imagesData.length).fill(null),
        folder: folder,
        id: slugBase,
      },
    })
  );

  const map: { [key: string]: string[] } = {};
  imagesData.forEach((_, index) => {
    map[index.toString()] = [`variables.files.${index}`];
  });

  formData.append("map", JSON.stringify(map));

  imagesData.forEach((image, index) => {
    formData.append(index.toString(), image);
  });

  return await uploadToServer(formData, "uploadMultipleImages");
};

// For single image upload
export const uploadImage = async (
  imageData: File,
  folder: string,
  id: string
) => {
  const formData = new FormData();
  const slugBase = urlSlug(id?.toLowerCase().trim(), {
    separator: "-",
  });
  formData.append(
    "operations",
    JSON.stringify({
      query: `
        mutation ($file: Upload!, $folder: String, $id: String) {
          uploadImage(file: $file,folder: $folder, id: $id) {
            encoding
            url
            filename
            mimetype
          }
        }
      `,
      variables: {
        file: null,
        folder: folder,
        id: slugBase,
      },
    })
  );
  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", imageData);

  return await uploadToServer(formData, "uploadImage");
};

export const uploadFile = async (videoData: File) => {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      query: `
        mutation ($file: Upload!) {
          uploadFile(file: $file) {
            encoding
            url
            filename
            mimetype
          }
        }
      `,
      variables: {
        file: null,
      },
    })
  );
  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", videoData);

  return await uploadToServer(formData, "uploadFile");
};

export const uploadExcel = async (excelData: File) => {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      query: `
       mutation UploadOrUpdateBusinesses($file:Upload!) {
    uploadOrUpdateBusinesses(file: $file) {
        message
        statusCode
        successfullyUploadedBusinesses
        errorUploadedBusinesses
        errors {
            data
            message
        }
    }
}
      `,
      variables: {
        file: null,
      },
    })
  );

  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", excelData);

  return await uploadToServer(formData, "uploadBusinessProfiles");
};
export const uploadImageExcel = async (excelData: File) => {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      query: `
             mutation UploadBusinessProfilesGallery($file:Upload!) {
             uploadBusinessProfilesGallery(file: $file) {
                      message
                      statusCode
                     successfullyUploadedBusinesses
                     errorUploadedBusinesses
                     errors {
                      data
                      message
                    }
                }
            }  `,
      variables: {
        file: null,
      },
    })
  );

  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", excelData);

  return await uploadToServer(formData, "uploadBusinessProfilesGallery");
};

export const uploadSubCategoryExcel = async (
  excelData: File,
  categoryId?: string
) => {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      query: `
        mutation UploadSubCategory($file: Upload!, $categoryId: ID) {
          uploadSubCategory(file: $file, categoryId: $categoryId) {
            message
            statusCode
          }
        }
      `,
      variables: {
        file: null,
        categoryId: categoryId,
      },
    })
  );

  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", excelData);

  if (categoryId) {
    formData.append("variables.categoryId", categoryId);
  }

  return await uploadToServer(formData, "uploadSubCategory");
};

export const hasPermission = (
  permissions: any[],
  type: string,
  action: string
): boolean => {
  const isEnvAdmin = getIsEnvAdmin();
  if (isEnvAdmin) {
    return true;
  }
  const permission = permissions?.find((perm) => perm.type === type);
  return permission ? Boolean(permission[action]) : false;
};

export const getDefaultRoute = (permissions: any[]): string => {
  if (hasPermission(permissions, "Events", "view")) {
    return ROUTES.EVENTS;
  }
  if (hasPermission(permissions, "Category", "view")) {
    return ROUTES.CATEGORY;
  }
  return ROUTES.DEFAULT;
};

export const getPermissions = (): any[] => {
  try {
    return JSON.parse(Cookies.get("permissions") ?? "[]");
  } catch {
    return [];
  }
};

export function hasPermissionPage(pageName: string, permissionType: string) {
  try {
    const permissionsCookie = Cookie.get("permissions");
    // If permissions cookie is not found, return false
    if (!permissionsCookie) {
      removeToken();
      console.warn("Permissions cookie not found.");
      return false;
    }

    // Parse the permissions JSON
    const permissions = JSON.parse(permissionsCookie);
    // Find the matching page permission object
    const pagePermission = permissions.find(
      (permission: any) =>
        permission.type.toLowerCase() === pageName.toLowerCase()
    );

    // Check if the specific permission type exists and is true
    return pagePermission ? pagePermission[permissionType] === true : false;
  } catch (error) {
    console.error(
      "Error parsing permissions or invalid data structure:",
      error
    );
    return false; // Return false if any error occurs
  }
}

export const navigateBasedOnPermissions = (
  navigate: any,
  delay: number = 1000
) => {
  const permissionsCookie = Cookies.get("permissions");

  if (!permissionsCookie) {
    console.error("Permissions not found in cookies");
    return;
  }

  try {
    const permissions:any = JSON.parse(permissionsCookie);

    if (permissions && permissions.length > 0) {
      const firstPermission = permissions.find((permission:any)=> permission.view === true)?.type?.toUpperCase();
      const permissionRoute = PERMISSION_ROUTES_LIST[firstPermission];
      if (firstPermission && permissionRoute) {
        setTimeout(() => {
          navigate(`${permissionRoute}`);
          window.location.reload();
        }, delay);
      }
    }
  } catch (error) {
    console.error("Failed to parse permissions from cookies:", error);
  }
};

import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CONTACT_US } from "src/graphql/query";
import { UPDATE_CONTACT_STATUS } from "src/graphql/mutations";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import { ClearIcon } from "@mui/x-date-pickers";
import ApprovalIcon from "@mui/icons-material/Approval";
import { toast } from "react-toastify";
import VerifyEventsDialog from "../Events/VerifyEvents";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";


enum ContactStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
import { hasPermissionPage } from "src/components/Common/Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./ContactUs.module.css";
import DeleteModel from "src/components/Common/DeleteModel";
import { DELETE_CONTACT_US } from "src/graphql/mutations";

interface ContactUs {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  subject: string;
  description: string;
  statusHistory: {
    status: ContactStatus;
    adminComment: string;
    updatedAt: string;
  }[];
}

const ContactUs: React.FC = () => {
  const [list, setList] = useState<ContactUs[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [verifyingByUsContact, setVerifyingByUsContact] = useState<
    string | null
  >(null);
  const [reason, setReason] = useState<string>("");
  const [verifyContactByUsProfileValue, setVerifyContactByUsProfileValue] =
    useState<string>("");
  const [updateContactStatus] = useMutation(UPDATE_CONTACT_STATUS);

  const handleVerifyByUs = async (eventId: string, status: string, adminComment: string): Promise<void> => {
    try {
      const newStatus = ContactStatus[status as keyof typeof ContactStatus];
      if (!newStatus) {
        toast.error("Invalid status provided");
      }
      const variables = {
        _id: eventId,
        input: { status: newStatus, adminComment },
      }
      const res = await updateContactStatus({
        variables
      });
      if (res?.data?.updateContactStatus) {
        const { statusCode, message } = res.data.updateContactStatus;
        if (statusCode === 200) {
          toast.success("Status updated successfully!");
          refetch();
          setReason("");
          setVerifyContactByUsProfileValue("");
          setVerifyingByUsContact(null);
        } else {
          toast.error(message || "Unknown error occurred.");
        }
      } else {
        toast.error("No data returned from mutation.");
      }
    } catch (error: any) {
      toast.error("Failed to update status. Please try again.");
      console.error("Error updating status:", error.message || error);
    }
  }
  const [deleteContactUsId, setDeleteContactUsId] = useState<string | null>(
    null
  );
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [RemoveContactUs] = useMutation(DELETE_CONTACT_US);

  const COLUMNS = [
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Email", field: "email", flex: 1 },
    { headerName: "Phone Number", field: "phoneNumber", flex: 1 },
    { headerName: "Subject", field: "subject", flex: 1 },
    { headerName: "Description", field: "description", flex: 2 },
    {
      headerName: "Reason",
      field: "reason",
      flex: 1,
      renderCell: ({ row }: { row: ContactUs }) => (
        <span>{row?.statusHistory[row.statusHistory.length - 1]?.adminComment}</span>
      )
    },
    {
      headerName: "Verified",
      field: "statusHistory[contact.statusHistory.length - 1]?.status",
      flex: 1,
      renderCell: ({ row }: { row: ContactUs }) => (
        <>
          {row?.statusHistory[row.statusHistory.length - 1]?.status === "APPROVED" ? (
            <Tooltip title="Approved">
              <IconButton aria-label="status" style={{ color: "#039487" }}>
                <TaskAltIcon />
              </IconButton>
            </Tooltip>
          ) : row?.statusHistory[row.statusHistory.length - 1]?.status === "PENDING" ? (
            <Tooltip title="Pending">
              <IconButton aria-label="status" style={{ color: "#ff9966" }}>
                <WarningIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Rejected">
              <IconButton aria-label="status" style={{ color: "#ff0000" }}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      headerName: "Verification",
      field: "Verification",
      width: 150,
      renderCell: ({ row }: any) => (
        <>
          <Tooltip title="Verify Contact">
            <IconButton
              onClick={() => setVerifyingByUsContact(row._id)}
              aria-label="status"
              style={{ color: "#869986" }}
            >
              <ApprovalIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },

    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <Box>
          {hasPermissionPage("ContactUs", "delete") && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => handleOpenDeleteModel(row._id)}
                aria-label="delete"
                className={styles.editButton}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_CONTACT_US, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data?.getContactUs?.data) {
      setList(data.getContactUs.data);
      setTotal(data.getContactUs.count);
    }
  }, [data]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };
  const handleOpenDeleteModel = (dealId: string) => {
    setDeleteContactUsId(dealId);
    setOpenDeleteModel(true);
  };
  const handleDelete = async () => {
    if (!deleteContactUsId) return;
    try {
      await RemoveContactUs({ variables: { id: deleteContactUsId } });
      refetch();
    } catch (error: any) {
      console.error("Failed to delete Contact Us", error);
    } finally {
      setOpenDeleteModel(false);
      setDeleteContactUsId(null);
    }
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        paddingRight={4}
      >
        <Typography variant="h4" color={"#00C5B9"}>
          Contact Us
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleDelete}
        />
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      <VerifyEventsDialog
        label="Contact By us"
        handleVerify={handleVerifyByUs}
        setVerifyingData={setVerifyingByUsContact}
        verifyingData={verifyingByUsContact}
        verifyDataValue={verifyContactByUsProfileValue}
        reason={reason}
        setVerifyDataValue={setVerifyContactByUsProfileValue}
        setReason={setReason}
      />
    </Box>
  );
};

export default ContactUs;

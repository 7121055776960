import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomButton from "src/components/CustomButton";
import CustomTable from "src/components/CustomTable";
import ClearIcon from "@mui/icons-material/Clear";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_EVENTS_ENUM_ALL } from "src/graphql/query";
import { UPDATE_EVENTS_ENUM_RANK } from "src/graphql/mutations";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import CreateEventEnum from "./CreateEventEnum";
import DeleteModel from "src/components/Common/DeleteModel";
import { toast } from "react-toastify";
import {
  DELETE_EVENT_ENUM,
  DELETE_MULTIPLE_EVENT_ENUM,
  DELETE_ALL_EVENT_ENUM,
} from "src/graphql/DeleteMutation";
import { getRole } from "src/components/Common/Utils";
import ActionMenu from "../Business/ActionMenu";
import { debounce } from "src/utils/debounce";
import ToggleSwitch from "src/components/Common/ToggleSwitch";
import SaveIcon from "@mui/icons-material/Save";

function EventEnum() {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState<any>([]);
  const [selectedEventEnum, setSelectedEventEnum] = useState<any>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [rankUpdates, setRankUpdates] = useState<{
    [key: string]: number | null;
  }>({});
  const [isRankEdit, setIsRankEdit] = useState<boolean>(false);
  // const [UpdateEventsEnumRank] = useMutation(UPDATE_EVENTS_ENUM_RANK);
  const [UpdateEventsEnumRank] = useMutation(UPDATE_EVENTS_ENUM_RANK);
  const [enumToDelete, setEnumToDelete] = useState<any>(null);
  const [DeleteEventsEnum] = useMutation(DELETE_EVENT_ENUM);
  const [selectedRows, setSelectedRows] = useState([]);
  const [DeleteMultipleEventsEnum] = useMutation(DELETE_MULTIPLE_EVENT_ENUM);
  const [DeleteAllEventsEnum] = useMutation(DELETE_ALL_EVENT_ENUM);
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const isSuperAdmin = getRole() === "SuperAdmin";
  const COLUMNS = [
    ...(isSuperAdmin
      ? [
        {
          headerName: (
            <Box display={"flex"} alignItems={"center"}>
              <Checkbox
                checked={
                  selectedRows.length === list.length && list.length > 0
                }
                indeterminate={
                  selectedRows.length > 0 && selectedRows.length < list.length
                }
                onChange={(event) => handleSelectAll(event.target.checked)}
              />
            </Box>
          ),
          field: "select",
          flex: 0.5,
          sortable: false,
          filterable: false,
          disableClickEventBubbling: true,
          renderCell: ({ row }: any) => (
            <Checkbox
              checked={selectedRows?.includes(row?._id as never)}
              onChange={() => handleRowSelect(row._id)}
            />
          ),
          headerClassName: "no-hover",
        },
      ]
      : []),
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Description", field: "description", flex: 1 },
    {
      headerName: "Rank",
      field: "rank",
      flex: 1,
      renderCell: ({ row }: { row: any }) => {
        const [rankValue, setRankValue] = useState(row?.rank || "");

        const handleRankChange = (value: string) => {
          setRankValue(value);
          setRankUpdates((prev) => ({
            ...prev,
            [row._id]: value === "" ? null : Number(value),
          }));
        };
        return isRankEdit ? (
          <TextField
            value={rankValue}
            onChange={(e) => handleRankChange(e.target.value)}
            placeholder="Enter Rank"
            variant="outlined"
            size="small"
            style={{ marginRight: "8px" }}
          />
        ) : (
          <Typography
            display="flex"
            alignItems="center"
            style={{ height: "100%" }}
          >
            {rankValue}
          </Typography>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <Box>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEdit(row)}
              aria-label="edit"
              style={{ marginRight: "8px", color: "#00C5B9" }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => handleDelete(row)}
              aria-label="delete"
              style={{ color: "#00C5B9" }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
  const handleRowSelect = (id: any) => {
    setSelectedRows((prevSelectedRows: any) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId: any) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      const allRowIds = list.map((row: any) => row._id);
      setSelectedRows(allRowIds as any);
    } else {
      setSelectedRows([]);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultipleEventsEnum({
        variables: {
          _id: selectedRows,
        },
      });
      if (res?.data?.deleteMultipleEventsEnum?.statusCode === 200) {
        toast.success(res?.data?.deleteMultipleEventsEnum?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultipleEventsEnum?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };

  const handleAllDelete = async () => {
    try {
      const res = await DeleteAllEventsEnum();
      if (res?.data?.deleteAllEventsEnum?.statusCode === 200) {
        toast.success(res?.data?.deleteAllEventsEnum?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteAllEventsEnum?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteAllModel(false);
    }
  };

  const handleEdit = (rowData: any) => {
    setSelectedEventEnum(rowData);
    setOpenModal(true);
  };
  const handleDelete = (rowData: any) => {
    setEnumToDelete(rowData);
    setOpenConfirmDialog(true);
  };
  const { loading, error, data, refetch } = useQuery(GET_EVENTS_ENUM_ALL, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
      search: searchTerm,
    },
  });
  useEffect(() => {
    if (data?.getAllEventsEnum) {
      setList(data?.getAllEventsEnum?.data);
      setTotal(data?.getAllEventsEnum?.total);
    }
  }, [data, refetch]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      refetch({
        search: term,
        page: 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.pageSize]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
    debouncedRefetch(value);
  };
  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };
  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
  };
  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedEventEnum(undefined);
  };

  const handleRankUpdate = async () => {
    const input = Object.keys(rankUpdates).map((id) => ({
      eventId: id,
      rank: rankUpdates[id],
    }));
    try {
      for (const { eventId, rank } of input) {
        try {
          const EnumEventRank = { _id: eventId, newRank: rank };
          const response = await UpdateEventsEnumRank({
            variables: EnumEventRank,
          });
          if (response?.data?.updateEventsEnumRank?.statusCode === 200) {
            toast.success(response.data.updateEventsEnumRank.message);
            setRankUpdates({});
          } else {
            toast.error(response.data.updateEventsEnumRank.message);
          }
        } catch (error) {
          toast.error(`${error}`);
        }
      }
    } catch (error: any) {
      toast.error(`Failed to update ranks: ${error.message}`);
    } finally {
      setIsRankEdit(false);
      refetch();
    }
  };
  const handleConfirmDelete = async () => {
    try {
      if (enumToDelete) {
        const res = await DeleteEventsEnum({
          variables: { _id: enumToDelete._id },
        });
        if (res?.data?.deleteEventsEnum?.statusCode === 200) {
          toast.success(res?.data?.deleteEventsEnum?.message);
          setEnumToDelete(null);
          setOpenConfirmDialog(false);
          refetch();
        } else {
          throw new Error(res?.data?.deleteEventsEnum?.message);
        }
      }
    } catch (error: any) {
      toast.error(
        error?.message || "An error occurred while deleting the user."
      );
    }
  };
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        paddingRight={4}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Events Enums
        </Typography>

        <Box display="flex" alignItems="center" gap={2}>
          <ToggleSwitch mode={isRankEdit} setMode={setIsRankEdit} />
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {isSuperAdmin &&
            <ActionMenu
              isSuperAdmin={isSuperAdmin}
              setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
              setOpenDeleteAllModel={setOpenDeleteAllModel}
            />
          }
          {isRankEdit && (
            <Tooltip title="Save">
              <Box width={50} mr={2}>
                <CustomButton
                  onClick={handleRankUpdate}
                  variant="contained"
                  sx={{ width: 50 }}
                >
                  <SaveIcon />
                </CustomButton>
              </Box>
            </Tooltip>
          )}
          <Box>
            <CustomButton
              onClick={() => setOpenModal(true)}
              variant="contained"
              className="width: 200px"
            >
              Create
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      <CreateEventEnum
        open={openModal}
        handleClose={() => handleCloseModal()}
        refetchDeals={refetch}
        selectedDealEnum={selectedEventEnum}
      />
      <DeleteModel
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this event enum?"
      />
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${selectedRows?.length ?? 0
            } event enums?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all event enum?"
        />
      )}
    </Box>
  );
}

export default EventEnum;

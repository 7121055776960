import React, { useEffect, useState } from "react";
import { TextField, Grid, Typography, FormControl, Box } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GET_APPROVED_BUSINESS,
  GET_BUSINESS,
  GET_DEALS_ENUM_ALL,
} from "src/graphql/query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import BusinessSelectDropDown from "src/components/Common/BusinessSelectDropDown";
import CustomButton from "src/components/CustomButton";
import Select from "react-select";
import {
  DealCategory,
  dealCategoryOptions,
  OfferType,
  offerTypeOptions,
  RepeatOption,
  repeatOptions,
  weekDays,
} from "./dealCategory";
import { CREATE_DEAL, UPDATE_DEAL } from "src/graphql/mutations";
import { uploadImage } from "src/components/Common/Utils";
import { toast } from "react-toastify";
import moment from "moment";
import backButton from "../../Images/backButton.svg";
import { debounce } from "src/utils/debounce";
import RichTextEditor from "src/components/Common/TextEditor";

interface BusinessOptions {
  value: string;
  label: string;
}

const CreateDeal = ({ reload, dealData, onClose }: any) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [businessOptions, setBusinessOptions] = useState([]);
  const [dealCategories, setDealCategories] = useState<any>([]);
  const [AddDeals] = useMutation(CREATE_DEAL);
  const [UpdateDeals] = useMutation(UPDATE_DEAL);

  const formic = useFormik({
    initialValues: {
      businessProfileId: "",
      title: "",
      image: "",
      category: "",
      area: "",
      offer: "",
      offerType: "",
      // discountBracket: { minimum: "", maximum: "" },
      discountBracket: { minimum: null, maximum: null },
      duration: { startDate: "", endDate: "" },
      repeatDuration: { repeat: "", weekDays: [] },
      description: "",
      termsAndConditions: "",
      metaTitle: "",
      metaDescription: "",
      focus: "",
    },
    validationSchema: Yup.object({
      businessProfileId: Yup.string().required("Business Profile Required"),
      title: Yup.string().required("Title is required"),
      category: Yup.string().required("Category is required"),
      area: Yup.string().required("Area is required"),
      offer: Yup.string().required("Offer is Required"),
      offerType: Yup.string().required("Discount is required"),
      // discountBracket: Yup.lazy(() => {
      //   return Yup.object().shape({
      //     minimum: Yup.number().when("offerType", {
      //       is: (val: OfferType) => val === OfferType.Percentage,
      //       then: (schema) => schema.required("Minimum is required"),
      //       otherwise: (schema) => schema.notRequired(),
      //     }),
      //     maximum: Yup.number().when("offerType", {
      //       is: (val: OfferType) => val === OfferType.Percentage,
      //       then: (schema) => schema.required("Maximum is required"),
      //       otherwise: (schema) => schema.notRequired(),
      //     }),
      //   });
      // }),
      discountBracket: Yup.lazy(() => {
        return Yup.object().shape({
          minimum: Yup.number()
            .nullable() // Allow null values
            .when("offerType", {
              is: (val: OfferType) => val === OfferType.Percentage,
              then: (schema) => schema.required("Minimum is required"),
              otherwise: (schema) => schema.notRequired(),
            }),
          maximum: Yup.number()
            .nullable() // Allow null values
            .when("offerType", {
              is: (val: OfferType) => val === OfferType.Percentage,
              then: (schema) => schema.required("Maximum is required"),
              otherwise: (schema) => schema.notRequired(),
            }),
        });
      }),
      duration: Yup.object({
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date().required("End date is required"),
      }),
      description: Yup.string().required("Description required"),
      termsAndConditions: Yup.string().required(
        "Terms and Conditions is required"
      ),
      metaTitle: Yup.string().required("Meta title is required"),
      metaDescription: Yup.string().required("Meta description is required"),
      focus: Yup.string().required("Focus keyword is required"),
    }),
    onSubmit: () => handleSubmit(),
  });

  const { data: dealCategory, error } = useQuery(GET_DEALS_ENUM_ALL, {
    onError: (err) => {
      toast.error("Facing issue in fetching categories");
      console.error("Query error:", err);
    },
    onCompleted: (data) => {
      const dealCategories = data?.getAllDealsEnum?.data ?? [];
      const optionObj = dealCategories?.map((item: any) => ({
        value: item?.name,
        label: item?.name,
      }));
      setDealCategories(optionObj);
    },
  });

  const handleClose = () => {
    formic.resetForm();
    setImageFile(null);
    setImagePreview(null);
    setSearchTerm("");
    onClose();
  };

  // Lazy query to fetch businesses based on search
  const [getBusinesses, { data }] = useLazyQuery(GET_APPROVED_BUSINESS, {
    variables: { search: searchTerm },
  });

  // Debounced function to set search term
  const handleSearchTermChange = debounce((term: string) => {
    setSearchTerm(term);
    getBusinesses();
  }, 300);

  useEffect(() => {
    getBusinesses();
  }, []);

  useEffect(() => {
    if (data) {
      setBusinessOptions(
        data?.getBusinessProfiles?.data?.map((business: any) => ({
          value: business?._id,
          // label: business?.businessName && business?.pinCodeDetails,
          label: `${business?.businessName} (${business?.pinCodeDetails})`,
        }))
      );
    }
  }, [data, getBusinesses]);

  const areaOptions: BusinessOptions[] =
    data?.getBusinessProfiles?.data?.reduce(
      (item: BusinessOptions[], data: any) => {
        if (formic?.values?.businessProfileId?.includes(data?._id)) {
          const addresses =
            data?.pinCodeDetails?.map((pinCode: string) => ({
              value: pinCode,
              label: pinCode,
            })) || [];
         
          return item.concat(addresses);
        }
        return item;
      },
      []
    ) || [];

  const handleSubmit = async () => {
    let imageUrl = formic.values.image;
    if (imageFile) {
      const Name = businessOptions?.filter(
        (data: any) => data?.value === formic.values.businessProfileId
      ) as any;
      const res = await uploadImage(
        imageFile,
        `business/${Name?.[0]?.label as any}/deals`,
        formic?.values?.title
      );
      imageUrl = res?.uploadImage?.url;
    }
    const updatedValues = JSON.parse(JSON.stringify(formic.values));
  if (updatedValues.discountBracket) {
    delete updatedValues.discountBracket;
  }
    // Use updatedValues where necessary

    try {
      const response = dealData
        ? await UpdateDeals({
            variables: {
              _id: dealData?._id,
              input: {
                ...formic.values,
                image: imageUrl,
              },
            },
          })
        : await AddDeals({
            variables: {
              input: {
                ...updatedValues,
                ...(updatedValues?.offerType === "Percentage" && {
                  discountBracket: {
                    minimum: formic.values?.discountBracket?.minimum,
                    maximum: formic.values?.discountBracket?.maximum,
                  },
                }),
                image: imageUrl,
              },
            },
          });
      if (response?.errors) {
        throw new Error(response?.errors as any);
      } else {
        toast.success(response?.data?.AddDeals?.message || "Deals");
        reload();
        formic.handleReset;
        handleClose();
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
      formic.setFieldValue("image", file.name);
    }
  };

  const offerText =
    formic.values.offerType === OfferType.Percentage
      ? `${formic.values.discountBracket.minimum}%-${formic.values.discountBracket.maximum}% OFF`
      : formic.values.offer;

  useEffect(() => {
    formic.setValues((prev: any) => ({
      ...prev,
      offer: offerText,
    }));
  }, [offerText]);

  useEffect(() => {
    if (dealData) {
      formic.setValues({
        businessProfileId: dealData?.businessProfileId || "",
        title: dealData.title || "",
        image: dealData.image || "",
        category: dealData.category || "",
        area: dealData.area || "",
        offer: dealData.offer || "",
        offerType: dealData.offerType || "",
        discountBracket: dealData.discountBracket
          ? {
              minimum: dealData.discountBracket?.minimum,
              maximum: dealData.discountBracket?.maximum,
            }
          : {
              minimum: "",
              maximum: "",
            },
        duration: dealData.duration
          ? {
              startDate: moment(dealData.duration?.startDate).format(
                "YYYY-MM-DD"
              ),
              endDate: moment(dealData.duration?.endDate).format("YYYY-MM-DD"),
            }
          : { startDate: "", endDate: "" },
        repeatDuration: dealData.repeatDuration
          ? {
              repeat: dealData.repeatDuration?.repeat,
              weekDays: dealData.repeatDuration?.weekDays,
            }
          : {
              repeat: "",
              weekDays: [],
            },
        description: dealData.description || "",
        termsAndConditions: dealData.termsAndConditions || "",
        metaTitle: dealData.metaTitle || "",
        metaDescription: dealData.metaDescription || "",
        focus: dealData.focus || "",
      });
    }
  }, [dealData]);

  const handleBusinessChange = (selectedOption: any | null) => {
    formic.setFieldValue("businessProfileId", selectedOption?.value || "");
    setSearchTerm(selectedOption ? selectedOption.label : "");
  };

  return (
    <Box p={2}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <img
          src={backButton}
          alt="backButton"
          height={25}
          width={25}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={handleClose}
        />
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: "#00C5B9" }}
          marginTop={2}
        >
          {dealData ? "Update Deal" : "Create Deal"}
        </Typography>
      </Box>
      <form onSubmit={formic.handleSubmit}>
        <Grid container spacing={2}>
          {/* Existing form fields */}
          <Grid item xs={12} sm={6}>
            <BusinessSelectDropDown
              selectedBusiness={formic.values.businessProfileId}
              handleBusinessChange={handleBusinessChange}
              businessOptions={businessOptions}
              setSearchTerm={handleSearchTermChange} // Pass debounced search term handler
            />
          </Grid>
          {/* Category and other fields */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formic.touched.category && Boolean(formic.errors.category)}
              sx={{ zIndex: 99999 }}
            >
              <Select
                options={dealCategories}
                value={dealCategories?.find(
                  (option: any) => option.value === formic.values.category
                )}
                isClearable
                onChange={(selectedOption: any) => {
                  const value = selectedOption ? selectedOption.value : "";
                  formic.setFieldValue("category", value as DealCategory);
                }}
                placeholder="Select Category"
              />
              {formic.touched.category && formic.errors.category && (
                <Typography variant="body2" color="error">
                  {formic.errors.category}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Other fields */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Title"
              name="title"
              value={formic.values.title}
              onChange={formic.handleChange}
              onBlur={formic.handleBlur}
              error={formic.touched.title && Boolean(formic.errors.title)}
              helperText={
                formic.touched.title && formic.errors.title
                  ? formic.errors.title
                  : ""
              }
            />
          </Grid>
          {/* Area */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formic.touched.area && Boolean(formic.errors.area)}
            >
              <Select
                options={areaOptions}
                onChange={(option) => {
                  formic.setFieldValue("area", option?.value);
                }}
                placeholder="Select Area"
                value={areaOptions?.find(
                  (option) => option?.value === formic.values.area
                )}
                isClearable
              />
              {formic.touched.area && formic.errors.area && (
                <div style={{ color: "red" }}>{formic.errors.area}</div>
              )}
            </FormControl>
          </Grid>
          {/* Start Date Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Start Date"
              name="duration.startDate"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formic.values.duration.startDate}
              onChange={formic.handleChange}
              onBlur={formic.handleBlur}
              error={
                formic.touched.duration?.startDate &&
                Boolean(formic.errors.duration?.startDate)
              }
              helperText={
                formic.touched.duration?.startDate &&
                formic.errors.duration?.startDate
              }
            />
          </Grid>
          {/* End Date Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="End Date"
              name="duration.endDate"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formic.values.duration.endDate}
              onChange={formic.handleChange}
              onBlur={formic.handleBlur}
              error={
                formic.touched.duration?.endDate &&
                Boolean(formic.errors.duration?.endDate)
              }
              helperText={
                formic.touched.duration?.endDate &&
                formic.errors.duration?.endDate
              }
            />
          </Grid>
       
          <Grid item xs={12}>
            <Typography>Description:</Typography>
            <RichTextEditor
              value={formic.values.description}
              onChange={(content: string) =>
                formic.setFieldValue("description", content)
              }
            />
            {formic.touched.description && formic.touched.description && (
              <Typography color="error">
                {formic.touched.description &&
                  Boolean(formic.errors.description)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography>Terms and Conditions:</Typography>
            <RichTextEditor
              value={formic.values.termsAndConditions}
              onChange={(content: string) =>
                formic.setFieldValue("termsAndConditions", content)
              }
            />
            {formic.touched.termsAndConditions &&
              formic.touched.termsAndConditions && (
                <Typography color="error">
                  {formic.touched.termsAndConditions &&
                    Boolean(formic.errors.termsAndConditions)}
                </Typography>
              )}
          </Grid>
       
          {/* Offer, Offer Type, and Discount Bracket fields */}
          <Grid item xs={12}>
            <Typography variant="h6">Offer:</Typography>
            <Typography>{offerText}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={
                formic.touched.offerType && Boolean(formic.errors.offerType)
              }
              sx={{ zIndex: 99999 }}
            >
              <Select
                options={offerTypeOptions}
                value={offerTypeOptions.find(
                  (option) => option.value === formic.values.offerType
                )}
                isClearable
                onChange={(selectedOption: any) => {
                  const value = selectedOption ? selectedOption.value : "";
                  formic.setFieldValue("offerType", value as OfferType);
                  if (value === OfferType.Percentage) {
                    formic.setFieldValue("discountBracket", {
                      minimum: "",
                      maximum: "",
                    });
                  }
                }}
                placeholder="Select Offer Type"
              />
              {formic.touched.offerType && formic.errors.offerType && (
                <Typography variant="body2" color="error">
                  {formic.errors.offerType}
                </Typography>
              )}
            </FormControl>
          </Grid>
          {formic.values.offerType === OfferType.Percentage && (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Minimum Discount"
                  name="discountBracket.minimum"
                  type="number"
                  value={formic.values.discountBracket.minimum}
                  onChange={formic.handleChange}
                  onBlur={formic.handleBlur}
                  error={
                    formic.touched.discountBracket?.minimum &&
                    Boolean(formic.errors.discountBracket?.minimum)
                  }
                  helperText={
                    formic.touched.discountBracket?.minimum &&
                    formic.errors.discountBracket?.minimum
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Maximum Discount"
                  name="discountBracket.maximum"
                  type="number"
                  value={formic.values.discountBracket.maximum}
                  onChange={formic.handleChange}
                  onBlur={formic.handleBlur}
                  error={
                    formic.touched.discountBracket?.maximum &&
                    Boolean(formic.errors.discountBracket?.maximum)
                  }
                  helperText={
                    formic.touched.discountBracket?.maximum &&
                    formic.errors.discountBracket?.maximum
                  }
                />
              </Grid>
            </>
          )}
          {formic.values.offerType === OfferType.Flat && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Offer"
                name="offer"
                type="text"
                value={formic.values.offer}
                onChange={formic.handleChange}
                onBlur={formic.handleBlur}
                error={formic.touched.offer && Boolean(formic.errors.offer)}
                helperText={formic.touched.offer && formic.errors.offer}
              />
            </Grid>
          )}
          {/* Repeat options using react-select */}
          <Grid item xs={12}>
            <Typography variant="h6">Repeat Options:</Typography>
            <FormControl fullWidth sx={{ zIndex: 9999 }}>
              <Select
                options={repeatOptions}
                value={repeatOptions.find(
                  (option) =>
                    option.value === formic.values.repeatDuration.repeat
                )}
                isClearable
                onChange={(selectedOption: any) => {
                  const value = selectedOption ? selectedOption.value : "";
                  formic.setFieldValue("repeatDuration.repeat", value);
                }}
                placeholder="Select Frequency"
              />
            </FormControl>
          </Grid>
          {/* Weekdays checkboxes using react-select */}
          {formic.values.repeatDuration.repeat !=
            RepeatOption.DOES_NOT_REPEAT && (
            <Grid item xs={12}>
              <Typography variant="h6">Select Week Days:</Typography>
              <FormControl sx={{ zIndex: "999" }}>
                <Select
                  options={weekDays}
                  isMulti
                  value={weekDays?.filter(
                    (day) =>
                      formic.values.repeatDuration?.weekDays?.includes(
                        day?.value as never
                      ) || ""
                  )}
                  onChange={(selectedOptions: any) => {
                    const selectedValues = selectedOptions
                      ? selectedOptions.map((option: any) => option.value)
                      : [];
                    formic.setFieldValue(
                      "repeatDuration.weekDays",
                      selectedValues
                    );
                  }}
                  placeholder="Select Days"
                />
              </FormControl>
            </Grid>
          )}
          {/* Image upload field */}
          <Grid item xs={12}>
            <Box>
              <Typography variant="body1">Deal Image:</Typography>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
                id="deal-image-upload"
              />
              <label htmlFor="deal-image-upload">
                <CustomButton variant="outlined" component="span">
                  Upload Image
                </CustomButton>
              </label>
              {(imagePreview || formic.values.image) && (
                <Box
                  sx={{
                    mt: 2,
                    width: "100%",
                    height: "auto",
                    borderRadius: 2,
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <img
                    src={imagePreview ?? formic.values.image}
                    alt="Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Meta Title"
              name="metaTitle"
              value={formic?.values?.metaTitle}
              onChange={formic.handleChange}
              fullWidth
              variant="outlined"
              error={
                formic.touched.metaTitle && Boolean(formic.errors.metaTitle)
              }
              helperText={
                formic.touched.metaTitle && (formic.errors.metaTitle as string)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text-area"
              label="Meta Description"
              name="metaDescription"
              value={formic?.values?.metaDescription}
              onChange={formic.handleChange}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              error={
                formic.touched.metaDescription &&
                Boolean(formic.errors.metaDescription)
              }
              helperText={
                formic.touched.metaDescription &&
                (formic.errors.metaDescription as string)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="text-area"
              label="Focus Keywords"
              name="focus"
              value={formic?.values?.focus}
              onChange={formic.handleChange}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              error={formic.touched.focus && Boolean(formic.errors.focus)}
              helperText={
                formic.touched.focus && (formic.errors.focus as string)
              }
            />
          </Grid>
          {/* Submit button */}
          <Grid item xs={12}>
            <CustomButton type="submit" variant="contained" color="primary">
              Submit
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CreateDeal;

import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  IconButton,
  Modal,
  Grid,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { CREATE_CONFIG_DATA, UPDATE_CONFIG_DATA } from "src/graphql/mutations";
import { GET_CONFIG_DATA } from "src/graphql/query";
import { toast } from "react-toastify";
import { uploadImages } from "src/components/Common/Utils";
import CustomButton from "src/components/CustomButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "src/components/Button";

interface ConfigDataModalProps {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
  configData?: any;
}

const ConfigDataNewModal: React.FC<ConfigDataModalProps> = ({
  open,
  onClose,
  refetch,
  configData,
}) => {
  const [formData, setFormData] = useState<any>(configData?.configData || {});
  const [newSectionName, setNewSectionName] = useState("");
  const [isBannerEnabled, setIsBannerEnabled] = useState(true);
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerTitles, setBannerTitles] = useState({
    ios: "",
    android: "",
    website: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const [bannerImages, setBannerImages] = useState<any[]>([]);
  const [vipDesktopBanners, setVipDesktopBanners] = useState<any[]>([]);
  const [vipMobileBanners, setVipMobileBanners] = useState<any[]>([]);
  const [debounceLinks, setDebounceLinks] = useState<any>([
    { url: "", redirect: "" },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;

  const [newField, setNewField] = useState<{
    [key: string]: { field: string; value: string | string[] };
  }>({
    SearchField: { field: "SearchField", value: [] },
  });

  const handleAddDebounceLink = () => {
    setDebounceLinks([...debounceLinks, { url: "", redirect: "" }]);
  };

  const handleDebounceLinkChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const updatedLinks = [...debounceLinks];
    updatedLinks[index][field] = value;
    setDebounceLinks(updatedLinks);
  };

  const handleDeleteDebounceLink = (index: number) => {
    const updatedLinks = debounceLinks?.filter(
      (_: any, i: number) => i !== index
    );
    setDebounceLinks(updatedLinks);
  };

  useEffect(() => {
    setFormData(configData?.configData || {});
    setIsBannerEnabled(configData?.configData?.Home?.bannerEnabled || false);
    setBannerTitle(configData?.configData?.Home?.bannerTitle || "");
    // Initialize the state correctly
    setBannerTitles({
      ios: configData?.configData?.Home?.bannerTitles?.ios || "",
      android: configData?.configData?.Home?.bannerTitles?.android || "",
      website: configData?.configData?.Home?.bannerTitles?.website || "",
    });

    setBannerImages(configData?.configData?.Home?.banners || []);
    setVipDesktopBanners(configData?.configData?.Home?.vipDesktopBanners || []);
    setVipMobileBanners(configData?.configData?.Home?.vipMobileBanners || []);
    setDebounceLinks(
      configData?.configData?.debounceLinks || [{ url: "", redirect: "" }]
    );
  }, [configData]);

  const [createConfigData] = useMutation(CREATE_CONFIG_DATA, {
    onCompleted: () => {
      refetch();
      resetForm();
      onClose();
    },
  });

  const [updateConfigData] = useMutation(UPDATE_CONFIG_DATA, {
    onCompleted: () => {
      refetch();
      resetForm();
      onClose();
    },
  });

  const handleUpdateField = (key: string, value: string | string[]) => {
    setNewField((prev) => ({
      ...prev,
      [key]: { field: key, value },
    }));
  };

  const handleFieldChange = (section: string, field: string, value: string) => {
    if (field === "SearchString") {
      const searchArray = value.split(",").map((item) => item.trim());

      setFormData((prevData: any) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [field]: searchArray,
        },
      }));
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [field]: value,
        },
      }));
    }
  };

  const handleDeleteSection = (section: string) => {
    setFormData((prevData: any) => {
      const updatedData = { ...prevData };
      delete updatedData[section];
      return updatedData;
    });
  };

  const handleAddSection = () => {
    if (!newSectionName.trim()) {
      toast.error("Please enter a valid section name.");
      return;
    }

    if (!formData[newSectionName]) {
      setFormData((prevData: any) => ({
        ...prevData,
        [newSectionName]: {
          metaTitle: "",
          metaDescription: "",
          metaKeywords: "",
          focusKeyword: "",
        },
      }));
      setNewSectionName("");
    } else {
      toast.error("Section already exists");
    }
  };

  const handleSave = async () => {
    try {
      const uploadedImageUrls = await Promise.all(
        bannerImages.map(async (image: File | string) => {
          if (typeof image !== "string") {
            const uploadedImage = await uploadImages([image], "banner", "Home");
            return uploadedImage?.uploadMultipleImages?.[0]?.url || "";
          } else {
            return image;
          }
        })
      );

      const uploadedVipDesktopUrls = await Promise.all(
        vipDesktopBanners.map(async ({ url }: { url: File | string }) => {
          if (typeof url !== "string") {
            const uploadedImage = await uploadImages(
              [url],
              "desktopBanner",
              "Home"
            );
            return uploadedImage?.uploadMultipleImages?.[0]?.url || "";
          } else {
            return url;
          }
        })
      );

      const uploadedVipMobileUrls = await Promise.all(
        vipMobileBanners.map(async ({ url }: { url: File | string }) => {
          if (typeof url !== "string") {
            const uploadedImage = await uploadImages(
              [url],
              "mobileBanner",
              "Home"
            );
            return uploadedImage?.uploadMultipleImages?.[0]?.url || "";
          } else {
            return url;
          }
        })
      );

      const updatedFormData = {
        ...formData,
        debounceLinks,
        Home: {
          ...(formData?.Home || {}),
          bannerEnabled: isBannerEnabled,
          bannerTitle,
          bannerTitles,
          banners: uploadedImageUrls,
          vipDesktopBanners: uploadedVipDesktopUrls?.map((url, idx) => ({
            url,
            title: vipDesktopBanners[idx].title,
          })),
          vipMobileBanners: uploadedVipMobileUrls?.map((url, idx) => ({
            url,
            title: vipMobileBanners[idx].title,
          })),
        },
      };

      const inputData = { configData: updatedFormData };

      if (configData) {
        const res = await updateConfigData({
          variables: { _id: configData._id, input: inputData },
        });
        if (res?.data?.updateConfigData?.statusCode === 200) {
          toast.success(
            res?.data?.updateConfigData?.message ||
              "Config data updated successfully"
          );
        } else {
          throw new Error(res?.data?.updateConfigData?.message);
        }
      } else {
        const res = await createConfigData({
          variables: { input: inputData },
          refetchQueries: [{ query: GET_CONFIG_DATA }],
        });
        if (res?.data?.addConfigData?.statusCode === 200) {
          toast.success(
            res?.data?.addConfigData?.message ||
              "Config data created successfully"
          );
        } else {
          throw new Error(res?.data?.addConfigData?.message);
        }
      }
    } catch (error: any) {
      toast.error(error.message);
      console.error(error.message);
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setBannerImages((prevImages) => [...prevImages, file]);
    }
  };

  const handleVipImageUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    setImages: React.Dispatch<any>
  ) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setImages((prevImages: any[]) => [
        ...prevImages,
        { url: file, title: "" },
      ]);
    }
  };

  const handleDeleteBannerImage = (index: number) => {
    setBannerImages((prevImages) =>
      prevImages.filter((_, idx) => idx !== index)
    );
  };
  const handleDeleteVipImage = (
    index: number,
    setImages: React.Dispatch<any>
  ) => {
    setImages((prevImages: any[]) =>
      prevImages.filter((_, idx) => idx !== index)
    );
  };

  const handleAddField = (section: string) => {
    const { field, value } = newField[section] || {};
    if (!field || field.trim() === "") {
      toast.error("Please enter a valid field name.");
      return;
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value || "",
      },
    }));
    setNewField((prevFields) => ({
      ...prevFields,
      [section]: { field: "", value: "" },
    }));
  };

  const resetForm = () => {
    setFormData(configData?.configData || {});
    setIsBannerEnabled(false);
    setBannerTitle("");
    setBannerTitles({ ios: "", android: "", website: "" });
    setBannerImages([]);
    setVipDesktopBanners([]);
    setVipMobileBanners([]);
  };
  const handleTitleChange = (platform: string, value: any) => {
    setBannerTitles((prev) => ({
      ...prev,
      [platform]: value,
    }));
  };

  const getFieldData = (field: any, value: any) => {
    const fieldHandlers: { [key: string]: (value: any) => any } = {
      vipMobileBanners: (value) => value?.[0]?.url, //for array value
      vipDesktopBanners: (value) => value?.[0]?.url,
      "0": (value) => value?.url, //for object value
      "1": (value) => value?.url,
    };

    return fieldHandlers[field] ? fieldHandlers[field](value) : value;
  };

  const handleOpenModal = (section: string) => {
    setSelectedSection(section);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedSection(null);
  };

  const totalPages = Math.ceil(debounceLinks.length / itemsPerPage);
  const paginatedLinks = debounceLinks.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <Box
      //   open={open}
      //   onClose={onClose}
      //   maxWidth="md"
      //   fullWidth
      //   sx={{ paddingY: 4 }}
      overflow={"scroll"}
      paddingX={20}
    >
      {/* <DialogTitle>
        {configData ? "Update Config Data" : "Create Config Data"}
      </DialogTitle> */}
      <DialogContent>
        <Box my={4}>
          <Box
            display="grid"
            gridTemplateColumns="repeat(4, 1fr)"
            gap={2}
            alignItems="center"
            mt={1}
          >
            <DialogTitle>
              {configData ? "Update Config Data" : "Create Config Data"}
            </DialogTitle>
            <Typography variant="h6">Add New Section</Typography>
            <TextField
              label="Section Name"
              value={newSectionName}
              onChange={(e) => setNewSectionName(e.target.value)}
              fullWidth
            />
            <CustomButton
              variant="outlined"
              onClick={handleAddSection}
              sx={{ height: "90%" }}
            >
              Add Section
            </CustomButton>
          </Box>
        </Box>

        <Box mt={3}>
          <Grid container spacing={3}>
            {Object?.keys(formData)?.map((section) => (
              <Grid item xs={12} sm={6} md={3} key={section}>
                <Box display="flex" alignItems="center">
                  <CustomButton
                    variant="outlined"
                    fullWidth
                    sx={{ textAlign: "left" }}
                    onClick={() => handleOpenModal(section)}
                  >
                    {section}
                  </CustomButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteSection(section)}
                    sx={{ ml: 2 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              maxWidth: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              maxHeight: "80vh",
              overflow: "auto",
            }}
          >
            {selectedSection && (
              <>
                <Typography variant="h6" gutterBottom>
                  Edit Section: {selectedSection}
                </Typography>
                {Object?.entries(formData[selectedSection] || {}).map(
                  ([field, value]: any) => {
                    const showValue = getFieldData(field, value);
                    return (
                      <TextField
                        key={field}
                        label={field}
                        value={showValue || ""}
                        onChange={(e) =>
                          handleFieldChange(
                            selectedSection,
                            field,
                            e.target.value
                          )
                        }
                        fullWidth
                        sx={{ mt: 2 }}
                      />
                    );
                  }
                )}
                <Box display="flex" gap={2} mt={2}>
                  <TextField
                    label="New Field Name"
                    value={newField[selectedSection]?.field || ""}
                    onChange={(e) =>
                      setNewField((prevFields) => ({
                        ...prevFields,
                        [selectedSection]: {
                          ...prevFields[selectedSection],
                          field: e.target.value,
                        },
                      }))
                    }
                    fullWidth
                  />
                  <TextField
                    label="Field Value"
                    value={newField[selectedSection]?.value || ""}
                    onChange={(e) =>
                      setNewField((prevFields) => ({
                        ...prevFields,
                        [selectedSection]: {
                          ...prevFields[selectedSection],
                          value: e.target.value,
                        },
                      }))
                    }
                    fullWidth
                  />
                  <CustomButton
                    variant="outlined"
                    onClick={() => handleAddField(selectedSection)}
                    sx={{ mt: 1 }}
                  >
                    Add Field
                  </CustomButton>
                </Box>
                <Box mt={4} textAlign="right">
                  <CustomButton variant="contained" onClick={handleCloseModal}>
                    Close
                  </CustomButton>
                </Box>
              </>
            )}
          </Box>
        </Modal>

        <Box mt={4}>
          <Typography variant="h6">Debounce Links</Typography>
          {paginatedLinks.map((link: any, index: number) => (
            <Box display="flex" alignItems="center" key={index} mt={2} gap={2}>
              <TextField
                label="Link URL"
                value={link.url}
                onChange={(e) =>
                  handleDebounceLinkChange(
                    (currentPage - 1) * itemsPerPage + index,
                    "url",
                    e.target.value
                  )
                }
                fullWidth
                sx={{ height: "20%" }}
              />
              <TextField
                label="Redirect URL"
                value={link.redirect}
                onChange={(e) =>
                  handleDebounceLinkChange(
                    (currentPage - 1) * itemsPerPage + index,
                    "redirect",
                    e.target.value
                  )
                }
                fullWidth
                sx={{ height: "20%" }}
              />
              <IconButton
                color="error"
                onClick={() =>
                  handleDeleteDebounceLink(
                    (currentPage - 1) * itemsPerPage + index
                  )
                }
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Box mt={2}>
            <CustomButton
              variant="outlined"
              onClick={handleAddDebounceLink}
              size="small"
            >
              Add Debounce Link
            </CustomButton>
          </Box>

          <Box display="flex" justifyContent="space-between" mt={2} gap={2}>
            <CustomButton
              variant="outlined"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </CustomButton>

            <CustomButton
              variant="outlined"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </CustomButton>
          </Box>
          <Typography>
            Page {currentPage} of {totalPages}
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h6">Banner Section</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isBannerEnabled}
                onChange={(e) => setIsBannerEnabled(e.target.checked)}
                name="bannerEnabled"
                color="primary"
              />
            }
            label="Enable Banner"
          />
          {isBannerEnabled && (
            <>
              <TextField
                label="Banner Title"
                value={bannerTitle}
                onChange={(e) => setBannerTitle(e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />
              <TextField
                label="Banner Title (iOS)"
                value={bannerTitles.ios}
                onChange={(e) => handleTitleChange("ios", e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />

              <TextField
                label="Banner Title (Android)"
                value={bannerTitles.android}
                onChange={(e) => handleTitleChange("android", e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />

              <TextField
                label="Banner Title (Website)"
                value={bannerTitles.website}
                onChange={(e) => handleTitleChange("website", e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />
              {bannerImages.map((image, index) => (
                <Box display="flex" alignItems="center" key={index} mt={2}>
                  <img
                    src={
                      typeof image !== "string"
                        ? URL.createObjectURL(image)
                        : image
                    }
                    alt={`Banner ${index + 1}`}
                    height={80}
                    width={80}
                    style={{ objectFit: "cover", borderRadius: 8 }}
                  />
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteBannerImage(index)}
                    sx={{ ml: 1 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Box mt={2}>
                <CustomButton variant="outlined" component="label" size="small">
                  Upload Banner Image
                  <input type="file" hidden onChange={handleImageUpload} />
                </CustomButton>
              </Box>
            </>
          )}
        </Box>
        {isBannerEnabled && (
          <Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap={4}
            mt={4}
          >
            <Box>
              <Typography variant="h6">VIP Desktop Banners</Typography>
              {vipDesktopBanners?.map((banner, index) => (
                <Box display="flex" alignItems="center" key={index} mt={2}>
                  <img
                    src={
                      typeof banner?.url !== "string"
                        ? URL.createObjectURL(banner?.url)
                        : banner?.url
                    }
                    alt={`VIP Desktop Banner ${index + 1}`}
                    height={80}
                    width={80}
                    style={{ objectFit: "cover", borderRadius: 4 }}
                  />
                  <TextField
                    label="Title"
                    value={banner.title}
                    onChange={(e) => {
                      const updatedBanners = [...vipDesktopBanners];
                      updatedBanners[index].title = e.target.value;
                      setVipDesktopBanners(updatedBanners);
                    }}
                    sx={{ ml: 2 }}
                  />
                  <IconButton
                    color="error"
                    onClick={() =>
                      handleDeleteVipImage(index, setVipDesktopBanners)
                    }
                    aria-label="delete"
                    sx={{ ml: 2 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Box mt={2}>
                <CustomButton variant="outlined" component="label" size="small">
                  Upload VIP Desktop Banner
                  <input
                    type="file"
                    hidden
                    onChange={(e) =>
                      handleVipImageUpload(e, setVipDesktopBanners)
                    }
                  />
                </CustomButton>
              </Box>
            </Box>
            <Box>
              <Typography variant="h6">VIP Mobile Banners</Typography>
              {vipMobileBanners.map((banner, index) => (
                <Box display="flex" alignItems="center" key={index} mt={2}>
                  <img
                    src={
                      typeof banner?.url !== "string"
                        ? URL.createObjectURL(banner?.url)
                        : banner.urls
                    }
                    alt={`VIP Mobile Banner ${index + 1}`}
                    height={80}
                    width={80}
                    style={{ objectFit: "cover", borderRadius: 4 }}
                  />
                  <TextField
                    label="Title"
                    value={banner.title}
                    onChange={(e) => {
                      const updatedBanners = [...vipMobileBanners];
                      updatedBanners[index].title = e.target.value;
                      setVipMobileBanners(updatedBanners);
                    }}
                    sx={{ ml: 2 }}
                  />
                  <IconButton
                    color="error"
                    onClick={() =>
                      handleDeleteVipImage(index, setVipMobileBanners)
                    }
                    aria-label="delete"
                    sx={{ ml: 2 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Box mt={2}>
                <CustomButton variant="outlined" component="label" size="small">
                  Upload VIP Mobile Banner
                  <input
                    type="file"
                    hidden
                    onChange={(e) =>
                      handleVipImageUpload(e, setVipMobileBanners)
                    }
                  />
                </CustomButton>
              </Box>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <CustomButton onClick={onClose} variant="contained" color="primary">
          Cancel
        </CustomButton>
        <CustomButton onClick={handleSave} variant="contained" color="primary">
          {configData ? "Update" : "Create"}
        </CustomButton>
      </DialogActions>
    </Box>
  );
};

export default ConfigDataNewModal;

import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FieldArray } from "formik";
import CustomButton from "src/components/CustomButton";
import PincodeSelectDropDown from "src/components/Common/PincodeSelectDropDown";
import deleteIcon from "src/Images/delete.svg";
import addIcon from "src/Images/Plus.svg";
const platforms = [
  { label: "Instagram", value: "INSTAGRAM" },
  { label: "Facebook", value: "FACEBOOK" },
  { label: "LinkedIn", value: "LINKEDIN" },
  { label: "WhatsApp", value: "WHATSAPP" },
  { label: "Snapchat", value: "SNAPCHAT" },
  { label: "Twitter", value: "TWITTER" },
  { label: "Swiggy", value: "SWIGGY" },
  { label: "Zomato", value: "ZOMATO" },
];
function AddressInfo({
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
  handleBack,
  setOverviewJson,
  subCategoryData,
  setCategoryId,
  handleNext,
}: any) {
  return (
    <Card>
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          fontSize={24}
          fontWeight={600}
          sx={{ alignSelf: "start", display: "flex" }}
          marginX={1}
        >
          Address Details
        </Typography>

        {/* address section  */}
        <Box mb={2}>
          <FieldArray name="addresses">
            {({ remove, push }) => (
              <div>
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignSelf={"self-end"}
                >
                  <CustomButton
                    type="button"
                    variant="outlined"
                    style={{
                      display: "flex",
                      width: 200,
                    }}
                    onClick={() =>
                      push({
                        address: "",
                        buildingOrShopNumber: "",
                        landmark: "",
                        neighborhood: "",
                        googleMapLink: "",
                        pinCode: "",
                      })
                    }
                  >
                    <Box gap={1} display={"flex"} alignItems={"center"}>
                      <img src={addIcon} alt="add" height={20} width={20}></img>
                      <span
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          lineHeight: "24px",
                          fontWeight: 400,
                        }}
                      >
                        Add Address
                      </span>
                    </Box>
                  </CustomButton>
                </Box>
                {values?.addresses?.length > 0 &&
                  values?.addresses?.map((address: any, index: number) => (
                    <Grid container spacing={2} key={index} mb={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Address"
                          fullWidth
                          name={`addresses.${index}.address`}
                          value={address.address || ""}
                          onChange={handleChange}
                          error={Boolean(
                            touched.addresses?.[index]?.address &&
                              errors.addresses?.[index]?.address
                          )}
                          helperText={
                            touched.addresses?.[index]?.address &&
                            errors.addresses?.[index]?.address
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Building/Shop Number"
                          fullWidth
                          name={`addresses.${index}.buildingOrShopNumber`}
                          value={address.buildingOrShopNumber || ""}
                          onChange={handleChange}
                          error={Boolean(
                            touched.addresses?.[index]?.buildingOrShopNumber &&
                              errors.addresses?.[index]?.buildingOrShopNumber
                          )}
                          helperText={
                            touched.addresses?.[index]?.buildingOrShopNumber &&
                            errors.addresses?.[index]?.buildingOrShopNumber
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Landmark"
                          fullWidth
                          name={`addresses.${index}.landmark`}
                          value={address.landmark || ""}
                          onChange={handleChange}
                          error={Boolean(
                            touched.addresses?.[index]?.landmark &&
                              errors.addresses?.[index]?.landmark
                          )}
                          helperText={
                            touched.addresses?.[index]?.landmark &&
                            errors.addresses?.[index]?.landmark
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Neighborhood"
                          fullWidth
                          name={`addresses.${index}.neighborhood`}
                          value={address.neighborhood || ""}
                          onChange={handleChange}
                          error={Boolean(
                            touched.addresses?.[index]?.neighborhood &&
                              errors.addresses?.[index]?.neighborhood
                          )}
                          helperText={
                            touched.addresses?.[index]?.neighborhood &&
                            errors.addresses?.[index]?.neighborhood
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {address.googleMapLink ? (
                          <TextField
                            margin="dense"
                            label="Google Map Link"
                            fullWidth
                            name={`addresses.${index}.googleMapLink`}
                            value={address.googleMapLink || ""}
                            onChange={handleChange}
                            error={Boolean(
                              touched.addresses?.[index]?.googleMapLink &&
                                errors.addresses?.[index]?.googleMapLink
                            )}
                            helperText={
                              touched.addresses?.[index]?.googleMapLink &&
                              errors.addresses?.[index]?.googleMapLink
                            }
                            InputProps={{
                              endAdornment: (
                                <Box
                                  component="a"
                                  href={address?.googleMapLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  sx={{
                                    cursor: "pointer",
                                    color: "primary.main",
                                    ml: 1,
                                  }}
                                >
                                  View
                                </Box>
                              ),
                            }}
                          />
                        ) : (
                          <TextField
                            margin="dense"
                            label="Google Map Link"
                            fullWidth
                            name={`addresses.${index}.googleMapLink`}
                            value={address.googleMapLink || ""}
                            onChange={handleChange}
                            error={Boolean(
                              touched.addresses?.[index]?.googleMapLink &&
                                errors.addresses?.[index]?.googleMapLink
                            )}
                            helperText={
                              touched.addresses?.[index]?.googleMapLink &&
                              errors.addresses?.[index]?.googleMapLink
                            }
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display={"flex"} gap={1}>
                          <Box my={2} minWidth={"400px"}>
                            <PincodeSelectDropDown
                              handlePincodeChange={(data) => {
                                setFieldValue(
                                  `addresses.${index}.pinCode`,
                                  data?.value
                                );
                              }}
                              selectedPincode={values?.addresses[0]?.pinCode}
                            />
                          </Box>
                          <Box my={2}>
                            <CustomButton
                              type="button"
                              variant="outlined"
                              onClick={() => remove(index)}
                              style={{
                                border: "0px",
                              }}
                            >
                              <Box
                                gap={1}
                                display={"flex"}
                                alignItems={"center"}
                              >
                                <img
                                  src={deleteIcon}
                                  alt="deleteIcon"
                                  height={20}
                                  width={20}
                                ></img>
                              </Box>
                            </CustomButton>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
              </div>
            )}
          </FieldArray>
        </Box>

        {/* social media handle  */}
        {/* Social Media Section */}
        <Typography
          variant="h6"
          gutterBottom
          fontSize={20}
          fontWeight={600}
          marginX={1}
        >
          Social Media
        </Typography>

        <FieldArray name="socialMedia">
          {({ remove, push }) => (
            <div>
              {values?.socialMedia?.length > 0 &&
                values?.socialMedia?.map((social: any, index: number) => (
                  <Grid
                    container
                    spacing={2}
                    key={index}
                    mb={2}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Grid item xs={12} sm={5}>
                      <TextField
                        select
                        margin="dense"
                        label="Platform"
                        fullWidth
                        name={`socialMedia.${index}.platform`}
                        value={social.platform.toUpperCase() || ""}
                        onChange={handleChange}
                        error={Boolean(
                          touched.socialMedia?.[index]?.platform &&
                            errors.socialMedia?.[index]?.platform
                        )}
                        helperText={
                          touched.socialMedia?.[index]?.platform &&
                          errors.socialMedia?.[index]?.platform
                        }
                      >
                        {platforms.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        margin="dense"
                        label="Handle URL"
                        fullWidth
                        name={`socialMedia.${index}.handle`}
                        value={social.handle || ""}
                        onChange={handleChange}
                        error={Boolean(
                          touched.socialMedia?.[index]?.handle &&
                            errors.socialMedia?.[index]?.handle
                        )}
                        helperText={
                          touched.socialMedia?.[index]?.handle &&
                          errors.socialMedia?.[index]?.handle
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={1}>
                      <CustomButton
                        type="button"
                        variant="outlined"
                        onClick={() => remove(index)}
                        style={{
                          border: "0px",
                        }}
                      >
                        <Tooltip title="Remove">
                          <Box gap={1} display={"flex"} alignItems={"center"}>
                            <img
                              src={deleteIcon}
                              alt="deleteIcon"
                              height={20}
                              width={20}
                            />
                          </Box>
                        </Tooltip>
                      </CustomButton>
                    </Grid>
                  </Grid>
                ))}

              <Box display={"flex"} justifyContent={"flex-end"} mb={2}>
                <CustomButton
                  type="button"
                  variant="outlined"
                  onClick={() => push({ platform: "", handle: "" })}
                >
                  <Box gap={1} display={"flex"} alignItems={"center"}>
                    <img src={addIcon} alt="add" height={20} width={20} />
                    <span>Add Social Media</span>
                  </Box>
                </CustomButton>
              </Box>
            </div>
          )}
        </FieldArray>

        {/* back and next navigation button  */}
        <Box display="flex" justifyContent="end" mt={2}>
          <Box marginX={2}>
            <CustomButton onClick={handleBack} variant="outlined">
              Back
            </CustomButton>
          </Box>
          <Box marginX={2}>
            <CustomButton
              variant="contained"
              onClick={() => {
                setOverviewJson(true);
                const res = subCategoryData.find((data: any) => {
                  return data?._id === values?.primarySubCategory;
                });
                setCategoryId(res?.categoryId[0] ?? "");
                handleNext();
              }}
            >
              Next
            </CustomButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default AddressInfo;
